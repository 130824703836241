import React from 'react'
import { MicroServices } from 'model/microServices'

export const MicroServiceContext = React.createContext<MicroServices>({
  displayNotification: () => {},
  currentAccount: {
    id: '',
    employer: { name: '' },
    user: { id: '', name: '', primaryEmail: '' }
  },
  maestroClient: undefined,

  children: undefined,
  hasAccessToViewRecommendations: false,
  hasAccessToViewDataViz: false,
  flags: []
})

export const MicroServiceProvider = (props: MicroServices): JSX.Element => {
  const { children } = props
  return (
    <MicroServiceContext.Provider value={props}>
      {children}
    </MicroServiceContext.Provider>
  )
}
