import { styled } from '@mui/material/styles'
import { Tabs as MuiTabsList } from '@mui/material'

const TabsList = styled(MuiTabsList)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
`

export default TabsList
