import React from 'react'
import { styled } from '@mui/material/styles'
import { Color } from 'components'
import { InputBase, Typography, InputBaseProps } from '@mui/material'
import { FormControl } from '../../styledComponents'

const TitleInput = styled(InputBase)<
  InputBaseProps | TitleInputWithCouterProps
>(({ fontSize }: any) => ({
  padding: '4px 0 8px',
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    lineHeight: 1.2,
    backgroundColor: Color.grey50,
    border: `2px solid ${Color.black}`,
    fontSize,
    fontWeight: 700,
    padding: '32px 16px'
  },
  '&.Mui-error': {
    '& .MuiInputBase-input': {
      borderColor: '#FD404C'
    }
  }
}))
type TitleInputWithCouterProps = {
  countLimit: number
  fontSize?: number
  label?: string
}
const TitleInputWithCouter = (
  props: InputBaseProps | TitleInputWithCouterProps
) => {
  const {
    value,
    countLimit = 300,
    fontSize = 24,
    label
  } = props as {
    value: string
    countLimit: number
    fontSize?: number
    label: string
  }

  return (
    <TitleInput
      fontSize={fontSize}
      endAdornment={
        <>
          <Typography
            variant='body2'
            sx={{ position: 'absolute', bottom: '12px', right: '8px' }}
          >
            {value.replace(/(\r\n|\n|\r)/g, ' ').length} / {countLimit}
          </Typography>
          <Typography
            variant='body2'
            sx={{ position: 'absolute', top: '12px', left: '16px' }}
          >
            {label}
          </Typography>
        </>
      }
      {...props}
    />
  )
}
export { FormControl, TitleInput, TitleInputWithCouter }
