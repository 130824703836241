import React from 'react'
import { Color } from 'components'
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const DataGridTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement='bottom' />
  )
)(() => ({
  left: '200px !important',
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '0 !important',
    backgroundColor: Color.main,
    color: Color.white,
    fontFamily: 'IBM Plex Sans',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    padding: '8px 10px'
  }
}))
