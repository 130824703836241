import React, { useContext } from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import Markdown from 'markdown-to-jsx'
import { RecommendationsContext } from 'context/Recommendations'
import { RecoAction } from 'model/recommendations'
import { ExpandedRecoSectionTitle } from '../styledComponents'

const ActionsToConsiderSection = () => {
  const { selectedReco } = useContext(RecommendationsContext)
  const { goalActions: recoActions } = selectedReco!

  if (!recoActions) return null
  return (
    <>
      {recoActions && recoActions.length > 0 && (
        <ExpandedRecoSectionTitle sectionTitle='Actions to Consider' />
      )}
      <Stack>
        {recoActions.map(({ title, description }: RecoAction) => {
          const actionTitle = title || ''
          const actionDescription = description || ''

          return (
            <>
              <Stack
                sx={{
                  padding: '16px 0',
                  '& .MuiTypography-root a': {
                    textDecoration: 'underline !important'
                  }
                }}
              >
                <Typography variant='body16'>
                  <Markdown
                    options={{
                      disableParsingRawHTML: true
                    }}
                  >
                    {actionTitle}
                  </Markdown>
                </Typography>
                <Typography variant='body16Light'>
                  <Markdown options={{ disableParsingRawHTML: true }}>
                    {actionDescription}
                  </Markdown>
                </Typography>
              </Stack>
              <Divider
                sx={{
                  marginBottom: 1,
                  paddingBottom: 1
                }}
              />
            </>
          )
        })}
      </Stack>
    </>
  )
}

export default ActionsToConsiderSection
