import React, { useContext, useState } from 'react'
import {
  Button,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { ActionPlanContext } from 'context/ActionPlan'
import { Member } from 'model/actionPlan'
import { MicroServiceContext } from 'context/MicroService'
import { UserAvatar } from 'components'
import SelectedMembers from './SelectedMembers'
import { ButtonSelect } from './styledComponents'
import MenuItemMember from '../components/MenuItemMember'
import MenuItemNoMembers from '../components/MenuItemNoMembers'

const TeamSelect = () => {
  const { currentAccount } = useContext(MicroServiceContext)
  const {
    isReadOnly,
    selectedEmployerGoal,
    submitEmployerGoal,
    membersWithAccess
  } = useContext(ActionPlanContext)
  const [isShowingMoreMembers, setIsShowingMoreMembers] = useState(false)

  let selectedGoalId = ''
  let teamMembersOnGoal: Member[] = []

  if (selectedEmployerGoal) {
    ;({ id: selectedGoalId, members: teamMembersOnGoal } = selectedEmployerGoal)
  }
  const teamMembersSelectLabel =
    teamMembersOnGoal?.length === 0 ? 'Team members' : 'Add teammates'

  const availableTeamMembers = membersWithAccess?.filter((member: any) =>
    selectedEmployerGoal
      ? selectedEmployerGoal?.owner?.id !== member.node.id &&
        !teamMembersOnGoal.find(m => m.id === member.node.id)
      : []
  )
  const teamTitle = 'Who’s on the team?'

  if (
    (isReadOnly && selectedEmployerGoal?.owner?.id !== currentAccount.id) ||
    selectedEmployerGoal?.isCompleted
  ) {
    return (
      <Grid item xs={6} marginTop={3}>
        <Typography variant='body16Light' component='p' marginBottom='8px'>
          {teamTitle}
        </Typography>
        {teamMembersOnGoal.length > 0 ? (
          teamMembersOnGoal.map((member: any) => (
            <Stack
              direction='row'
              key={`member-${member.name}`}
              padding='8px 0'
              alignItems='center'
            >
              <UserAvatar
                username={member.name ?? member.primaryEmail}
                size={32}
              />
              <Stack marginLeft={1}>
                <Typography variant='body14'>{member.name}</Typography>
                <Typography variant='body14Light'>
                  {member.primaryEmail}
                </Typography>
              </Stack>
            </Stack>
          ))
        ) : (
          <Typography variant='body16'>No team members</Typography>
        )}
      </Grid>
    )
  }
  return (
    <Grid item xs={6} marginTop={3}>
      <Typography variant='body16'>{teamTitle}</Typography>
      <ButtonSelect
        fullWidth
        sx={{
          '& .MuiSelect-select': {
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '16px'
          }
        }}
      >
        <Select
          renderValue={() => (
            <Typography variant='body16'>{teamMembersSelectLabel}</Typography>
          )}
          value={{}}
          MenuProps={{
            anchorOrigin: {
              vertical: 66,
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            PaperProps: { sx: { maxHeight: 270 } }
          }}
          onChange={e => {
            let thisTeamMembers = [...teamMembersOnGoal]
            thisTeamMembers.push(e.target.value as Member)
            thisTeamMembers = thisTeamMembers.map((m: any) => {
              const payload = {
                id: m.id,
                name: m.name,
                primaryEmail: m.primaryEmail
              }
              return payload
            })
            submitEmployerGoal({ members: thisTeamMembers }, 'Team Members')
          }}
        >
          {availableTeamMembers?.length > 0 ? (
            availableTeamMembers.map((member: any) => {
              const {
                node: { id, email }
              } = member
              return (
                <MenuItem value={{ id, primaryEmail: email } as any} key={id}>
                  <MenuItemMember name={email} primaryEmail={email} />
                </MenuItem>
              )
            })
          ) : (
            <MenuItemNoMembers />
          )}
        </Select>
      </ButtonSelect>
      <SelectedMembers isShowingMore={isShowingMoreMembers} />
      {teamMembersOnGoal.length > 5 && (
        <Button
          variant='underline'
          sx={{
            marginLeft: 'auto',
            display: 'block',
            marginTop: '8px'
          }}
          onClick={() => {
            setIsShowingMoreMembers(!isShowingMoreMembers)
          }}
        >
          View {isShowingMoreMembers ? 'less' : 'more'}
        </Button>
      )}
    </Grid>
  )
}

export default TeamSelect
