/* eslint-disable no-underscore-dangle */
import React from 'react'
import { useScrollTrigger } from '@mui/material'

export default function ElevationScroll(props: any) {
  const windowThreshold = (window as any).__POWERED_BY_QIANKUN__
    ? 48 + 48 + 332 + 48 + 160
    : 48 + 48 + 332 + 16 // the height above category panel

  const { children, ...other } = props
  const panelTarget = document.getElementById('scroll-card-view-panel')
  const cardViewListTarget = document.getElementById('scroll-card-view-list')
  const windowScrolltrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: windowThreshold
  })
  const panelScrolltrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: panelTarget || undefined
  })
  const listScrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: cardViewListTarget || undefined
  })
  return React.cloneElement(children, {
    style: {
      boxShadow:
        panelScrolltrigger || listScrollTrigger || windowScrolltrigger
          ? '0 3px 4px -2px #e5e5e5'
          : 'none',
      transition: 'box-shadow 0.3s'
    },
    ...other
  })
}
