import React, { useContext } from 'react'
import { SharedActionPlanContext } from 'context/SharedActionPlan'
import { GoalCounter, GoalCategory } from 'model/actionPlan'
import { Typography, TableRow } from '@mui/material'
import {
  CATEGORY_COLOR_4_TEXT,
  CATEGORY_COLOR_1_BKGD,
  CATEGORY_BAR_GRADIENT
} from 'constants/'

import { theme } from 'components'
import { MiniCell } from '../styledComponents'
import GoalCountTag from '../GoalCountTag'

interface Props {
  goalCategory: string
  score: any
}
const MiniCard = ({ goalCategory, score }: Props) => {
  const { employerGoalCount: employerGoalCountByCategory } = useContext(
    SharedActionPlanContext
  )
  const employerGoalCount =
    employerGoalCountByCategory[goalCategory as keyof GoalCounter]
  const categoryTotal = employerGoalCount?.total ?? 'N/A'
  const scorePercent = score / 25
  // the "scorePercent" above is just a placeholder until we actually calculate the scorePercent

  const title = GoalCategory[goalCategory as keyof typeof GoalCategory]
  const color = CATEGORY_COLOR_4_TEXT[title]
  const tagColor = CATEGORY_COLOR_1_BKGD[title]

  const borderBottom =
    goalCategory === 'onboarding_advancing'
      ? '1px solid #ACADAF'
      : '1px solid #E5E5E5'
  const gradient = CATEGORY_BAR_GRADIENT[title]

  return (
    <TableRow
      sx={{ display: 'flex', height: '44px', borderBottom: { borderBottom } }}
    >
      <MiniCell width='22.5%'>
        <Typography
          variant='body14Light'
          component='div'
          sx={{
            [theme.breakpoints.down(1000)]: {
              fontSize: '9.5px !important'
            }
          }}
        >
          {title}
        </Typography>
      </MiniCell>

      <MiniCell
        width='43.5%'
        sx={{
          background:
            'repeating-linear-gradient(90deg, #E5E5E5, #E5E5E5 1px, white 0px, white 20%)'
        }}
      >
        <Typography
          component='div'
          sx={{
            height: '.75em',
            borderRadius: '0px 4px 4px 0px',
            background: gradient,
            width: scorePercent,
            marginTop: '4px'
          }}
        />
      </MiniCell>
      <MiniCell
        width='12.5%'
        sx={{
          textAlign: 'center',
          borderRight: '1px solid #E5E5E5',
          borderLeft: '1px solid #E5E5E5',
          fontWeight: '700'
        }}
      >
        <Typography variant='h6' textAlign='center'>
          {score}
        </Typography>
      </MiniCell>
      <MiniCell
        width='21.5%'
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <GoalCountTag
          label={categoryTotal}
          tagColor={tagColor}
          textColor={color}
        />
      </MiniCell>
    </TableRow>
  )
}
export default MiniCard
