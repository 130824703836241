import React from 'react'
import { Grid } from '@mui/material'
import EffortSelect from './EffortSelect'
import ImpactSelect from './ImpactSelect'

const ImpactEffortSection = () => {
  return (
    <Grid
      item
      xs={6}
      marginTop={3}
      display='flex'
      justifyContent='space-between'
    >
      <ImpactSelect />
      <EffortSelect />
    </Grid>
  )
}

export default ImpactEffortSection
