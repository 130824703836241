import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import { AlertContext } from 'context/AlertContext'
import {
  EmployerGoal,
  EmployerGoalNode,
  MemberEdge,
  UpdatingEmployerGoal
} from 'model/actionPlan'
import {
  EMPLOYER_GOALS,
  GET_EMPLOYER_STAFF_ACCOUNTS,
  UPDATE_EMPLOYER_GOAL,
  UPDATE_EMPLOYER_GOAL_RANK
} from 'context/ActionPlan/gql'
import { useSearchParams } from 'react-router-dom'
import useCustomEmployerGoal from 'hooks/useCustomEmployerGoal'
import { SharedActionPlanContext } from '../SharedActionPlan'

export type EmployerGoalManager = {
  isLoading: boolean
  isReadOnly: boolean
  setIsReadOnly: (bool: boolean) => void
  isCreateGoalSidePanelOpen: boolean
  setIsCreateGoalSidePanelOpen: (bool: boolean) => void
  orderedEmployerGoals: EmployerGoalNode[]
  membersWithAccess: MemberEdge[]
  selectedEmployerGoal?: EmployerGoal
  setSelectedEmployerGoalId: (str: string) => void
  loadSelectedEmployerGoalAndSearchParams: (nextId?: string) => void
  submitEmployerGoal: (input: UpdatingEmployerGoal, fieldName?: string) => void
  hasAccessToSearchParamGoal: boolean
  submitEmployerGoalRank: (id: string, newRank: number) => void
}

export const ActionPlanContext = createContext<EmployerGoalManager>({
  isLoading: true,
  isReadOnly: true,
  isCreateGoalSidePanelOpen: false,
  setIsCreateGoalSidePanelOpen: () => {},
  orderedEmployerGoals: [],
  membersWithAccess: [],
  selectedEmployerGoal: undefined,
  setIsReadOnly: () => {},
  setSelectedEmployerGoalId: () => {},
  submitEmployerGoal: () => {},
  loadSelectedEmployerGoalAndSearchParams: () => {},
  hasAccessToSearchParamGoal: false,
  submitEmployerGoalRank: () => {}
})

export const ActionPlanProvider = ({ children }: any): JSX.Element => {
  const { currentAccount } = useContext(MicroServiceContext)
  const { displayAlertMessage } = useContext(AlertContext)
  const { employerGoals } = useContext(SharedActionPlanContext)
  const isLoading = !employerGoals
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)
  const [selectedEmployerGoal, setSelectedEmployerGoal] = useState<
    EmployerGoal | undefined
  >(undefined)
  const [selectedEmployerGoalId, setSelectedEmployerGoalId] = useState('')
  const [isCreateGoalSidePanelOpen, setIsCreateGoalSidePanelOpen] =
    useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [hasAccessToSearchParamGoal, setHasAccessToSearchParamGoal] =
    useState(true)
  const { updateCustomEmployerGoal } = useCustomEmployerGoal()
  const firstRender = useRef(true)

  const [updateEmployerGoal, { error: updateGoalError }] =
    useMutation(UPDATE_EMPLOYER_GOAL)
  const [updateEmployerGoalRank, { error: updateRankError }] = useMutation(
    UPDATE_EMPLOYER_GOAL_RANK,
    {
      refetchQueries: [
        {
          query: EMPLOYER_GOALS,
          variables: { employerId: currentAccount.employer.id }
        }
      ]
    }
  )

  const submitEmployerGoal = useCallback(
    async (input: UpdatingEmployerGoal, fieldName?: string) => {
      const { id } = selectedEmployerGoal!
      if (selectedEmployerGoal!.createdType === 'custom') {
        await updateCustomEmployerGoal({
          id,
          ...input
        })
      } else {
        await updateEmployerGoal({
          variables: { input: { id, attributes: input } }
        })
      }

      if (fieldName === 'Goal Owner Removal') {
        if (!updateGoalError) displayAlertMessage(`Goal Owner removed`)
      } else if (!updateGoalError) displayAlertMessage(`${fieldName} updated`)
    },
    [
      selectedEmployerGoal,
      updateGoalError,
      displayAlertMessage,
      updateCustomEmployerGoal,
      updateEmployerGoal
    ]
  )

  const submitEmployerGoalRank = useCallback(
    async (id: string, newRank: number) => {
      await updateEmployerGoalRank({
        variables: { input: { id, newRank } }
      })
      if (!updateRankError) displayAlertMessage(`Ranks updated`)
    },
    [updateEmployerGoalRank, displayAlertMessage, updateRankError]
  )

  const loadSelectedEmployerGoalAndSearchParams = useCallback(
    (nextId: string = '') => {
      setSelectedEmployerGoalId(nextId)
      if (nextId) {
        searchParams.set('id', nextId)
      } else {
        searchParams.delete('id')
      }
      setSearchParams(searchParams, { replace: true })
    },
    [searchParams, setSearchParams]
  )

  useEffect(() => {
    if (employerGoals) {
      const edges = [...employerGoals.edges]
      setSelectedEmployerGoal(
        edges.find(({ node }: any) => node?.id === selectedEmployerGoalId)?.node
      )
      const searchParamId = searchParams.get('id') || ''
      if (searchParamId.length > 0) {
        const searchParamGoal: EmployerGoalNode = edges.find(
          ({ node }) => node.id === searchParamId
        )
        if (searchParamGoal) {
          if (firstRender.current === true) {
            firstRender.current = false
            loadSelectedEmployerGoalAndSearchParams(searchParamId)
          } else {
            setHasAccessToSearchParamGoal(true)
          }
        } else {
          setHasAccessToSearchParamGoal(false)
        }
      }
    }
  }, [
    employerGoals,
    selectedEmployerGoalId,
    searchParams,
    loadSelectedEmployerGoalAndSearchParams
  ])

  const { data: employerStaffWithAccess } = useQuery(
    GET_EMPLOYER_STAFF_ACCOUNTS
  )

  const membersWithAccess = employerStaffWithAccess?.employerStaffAccounts.edges

  const orderedEmployerGoals: EmployerGoalNode[] = useMemo(() => {
    if (employerGoals) {
      const rawGoals = [...employerGoals.edges]
      return rawGoals?.map(goal => ({
        ...goal,
        __reorder__: goal.node.title
      }))
    }
    return []
  }, [employerGoals])

  const actionPlanValues = useMemo(
    () => ({
      isLoading,
      isReadOnly,
      setIsReadOnly,
      isCreateGoalSidePanelOpen,
      setIsCreateGoalSidePanelOpen,
      orderedEmployerGoals,
      membersWithAccess,
      selectedEmployerGoal,
      setSelectedEmployerGoalId,
      submitEmployerGoal,
      loadSelectedEmployerGoalAndSearchParams,
      hasAccessToSearchParamGoal,
      submitEmployerGoalRank
    }),
    [
      isLoading,
      isReadOnly,
      setIsReadOnly,
      isCreateGoalSidePanelOpen,
      setIsCreateGoalSidePanelOpen,
      orderedEmployerGoals,
      membersWithAccess,
      selectedEmployerGoal,
      submitEmployerGoal,
      loadSelectedEmployerGoalAndSearchParams,
      hasAccessToSearchParamGoal,
      submitEmployerGoalRank
    ]
  )

  return (
    <ActionPlanContext.Provider value={actionPlanValues}>
      {children}
    </ActionPlanContext.Provider>
  )
}
