import { FormControl, styled } from '@mui/material'
import { Color } from 'components'

const ButtonSelect = styled(FormControl)({
  marginTop: '16px',
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${Color.grey300}`,
    backgroundColor: Color.grey50,
    borderRadius: 8
  },
  '& .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': { borderWidth: 0 }
  }
})

export { ButtonSelect }
