import { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { RECOMMENDATION } from 'context/Recommendations/gql'
import { MicroServiceContext } from 'context/MicroService'

const useRecos = () => {
  const [recommendations, setRecommendations] = useState<any>()
  const { currentAccount } = useContext(MicroServiceContext)
  const { data: rawRecos } = useQuery(RECOMMENDATION, {
    variables: { employerId: currentAccount.employer.id }
  })

  useEffect(() => {
    if (rawRecos) {
      const { recommendations: recos } = rawRecos
      setRecommendations(recos)
    }
  }, [rawRecos])

  return { recommendations }
}

export default useRecos
