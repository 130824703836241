import React, { useContext } from 'react'
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { ActionPlanContext } from 'context/ActionPlan'
import { Member } from 'model/actionPlan'
import { MicroServiceContext } from 'context/MicroService'
import { UserAvatar } from 'components'
import MenuItemMember from './components/MenuItemMember'
import MenuItemNoMembers from './components/MenuItemNoMembers'

const OwnerSelect = () => {
  const { currentAccount } = useContext(MicroServiceContext)
  const {
    isReadOnly,
    selectedEmployerGoal,
    submitEmployerGoal,
    membersWithAccess
  } = useContext(ActionPlanContext)

  let selectedGoalId = ''
  let teamMembersOnGoal: Member[] = []

  if (selectedEmployerGoal) {
    ;({ id: selectedGoalId, members: teamMembersOnGoal } = selectedEmployerGoal)
  }

  const availableTeamMembers = membersWithAccess?.filter((member: any) =>
    selectedEmployerGoal
      ? selectedEmployerGoal?.owner?.id !== member.node.id &&
        !teamMembersOnGoal.find(m => m.id === member.node.id)
      : []
  )
  const ownerTitle = 'Who will work on this goal?'

  if (
    (isReadOnly && selectedEmployerGoal?.owner?.id !== currentAccount.id) ||
    selectedEmployerGoal?.isCompleted
  ) {
    return (
      <Grid item xs={6} marginTop={3}>
        <Typography variant='body16Light' component='p' marginBottom='8px'>
          {ownerTitle}
        </Typography>
        {selectedEmployerGoal?.owner?.id ? (
          <Stack
            direction='row'
            key='goal-owner'
            padding='8px 0'
            alignItems='center'
          >
            <UserAvatar
              username={
                selectedEmployerGoal.owner.name ??
                selectedEmployerGoal?.owner.primaryEmail
              }
              size={32}
            />
            <Stack marginLeft={1}>
              <Typography variant='body14'>
                {selectedEmployerGoal.owner.name}
              </Typography>
              <Typography variant='body14Light'>
                {selectedEmployerGoal.owner.primaryEmail}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Typography variant='body16'>No goal owner</Typography>
        )}
      </Grid>
    )
  }
  return (
    <Grid item xs={6} marginTop={3}>
      <Typography variant='body16'>{ownerTitle}</Typography>
      <FormControl
        fullWidth
        variant='filled'
        sx={{
          '& .MuiInputLabel-root': {
            transform: 'translate(16px, 20px) scale(1)'
          },
          '& .MuiInputBase-root .MuiSelect-select': {
            paddingTop: '30px',
            paddingBottom: '10px',
            paddingLeft: '16px'
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(16px, 10px) scale(1)'
          },
          '& .MuiInputLabel-shrink .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px'
          }
        }}
      >
        <InputLabel>
          <Typography variant='body16'>Add a goal owner</Typography>
        </InputLabel>
        <Select
          value={selectedEmployerGoal?.owner}
          renderValue={(member: any) => {
            return (
              <Typography variant='body16'>
                {member?.name ?? member?.primaryEmail}
              </Typography>
            )
          }}
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: 66,
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            PaperProps: { sx: { maxHeight: 270 } }
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={e => {
            const {
              id: ownerId,
              name,
              primaryEmail
            }: Member = e.target.value as Member
            const payload = {
              id: ownerId,
              name,
              primaryEmail
            }
            submitEmployerGoal({ owner: payload }, 'Goal Owner')
          }}
          endAdornment={
            selectedEmployerGoal?.owner?.name ? (
              <IconButton
                edge='end'
                onClick={() => {
                  submitEmployerGoal(
                    { owner: {} as Member },
                    'Goal Owner Removal'
                  )
                }}
                sx={{ position: 'absolute', right: '44px' }}
              >
                <CloseIcon />
              </IconButton>
            ) : undefined
          }
        >
          {availableTeamMembers?.length > 0 ? (
            availableTeamMembers.map((member: any) => {
              const {
                node: { id, email }
              } = member
              return (
                <MenuItem value={{ id, primaryEmail: email } as any} key={id}>
                  <MenuItemMember name={email} primaryEmail={email} />
                </MenuItem>
              )
            })
          ) : (
            <MenuItemNoMembers />
          )}
        </Select>
      </FormControl>
    </Grid>
  )
}

export default OwnerSelect
