import React from 'react'
import { styled } from '@mui/material/styles'
import Chip, { ChipProps } from '@mui/material/Chip'
import { Typography } from '@mui/material'
import { GOAL_STATUS_COLOR } from 'constants/'

const StatusChip = styled(({ label, ...props }: ChipProps) => (
  <Chip
    {...props}
    label={
      <Typography variant='subtitle2' component='div'>
        {label}
      </Typography>
    }
  />
))(({ label }: ChipProps) => ({
  backgroundColor: GOAL_STATUS_COLOR[label as keyof typeof GOAL_STATUS_COLOR],
  textTransform: 'uppercase'
}))

export default StatusChip
