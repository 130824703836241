import React, { useContext } from 'react'
import { Grid, Link } from '@mui/material'
import { ActionPlanContext } from 'context/ActionPlan'
import { parseUrl, convertUrlToRelativePath } from 'utils/KCUrlPaser'
import KCItem from './KCItem'

const ResourcesSection = () => {
  const { selectedEmployerGoal } = useContext(ActionPlanContext)
  let category: string = ''
  let kcUrls: string[] = []
  if (selectedEmployerGoal) {
    ;({ category, kcUrls } = selectedEmployerGoal)
  } else {
    return null
  }

  return (
    <>
      {kcUrls.map((link: string) => {
        const content = parseUrl(link)
        const relativeLink = convertUrlToRelativePath(link)
        return (
          <Grid item xs={6} marginTop={2}>
            <Link
              href={relativeLink}
              target='_blank'
              rel='noreferrer'
              style={{
                color: 'unset',
                textDecoration: 'unset',
                position: 'relative',
                display: 'block'
              }}
            >
              <KCItem content={content} goalCategory={category} />
            </Link>
          </Grid>
        )
      })}
    </>
  )
}

export default ResourcesSection
