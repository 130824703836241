import React, { useContext } from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import Markdown from 'markdown-to-jsx'
import { ActionPlanContext } from 'context/ActionPlan'
import { EmployerGoalAction } from 'model/actionPlan'
import { PanelSectionTitle } from '../../styledComponents'

const ActionsToConsiderSection = () => {
  const { selectedEmployerGoal } = useContext(ActionPlanContext)
  if (!selectedEmployerGoal) return null

  const { employerGoalActions } = selectedEmployerGoal

  if (!employerGoalActions || employerGoalActions?.length < 1) return null

  return (
    <>
      <PanelSectionTitle sectionTitle='Actions to Consider' />
      <Stack>
        {employerGoalActions?.map(
          ({ title, description }: EmployerGoalAction) => {
            const actionTitle = title || ''
            const actionDescription = description || ''

            return (
              <>
                <Stack
                  sx={{
                    padding: '16px 0',
                    '& .MuiTypography-root a': {
                      textDecoration: 'underline !important'
                    }
                  }}
                >
                  <Typography variant='body16'>
                    <Markdown
                      options={{
                        disableParsingRawHTML: true
                      }}
                    >
                      {actionTitle}
                    </Markdown>
                  </Typography>
                  <Typography variant='body16Light'>
                    <Markdown options={{ disableParsingRawHTML: true }}>
                      {actionDescription}
                    </Markdown>
                  </Typography>{' '}
                </Stack>
                <Divider
                  sx={{
                    marginBottom: 1,
                    paddingBottom: 1
                  }}
                />
              </>
            )
          }
        )}
      </Stack>
    </>
  )
}

export default ActionsToConsiderSection
