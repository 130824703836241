export enum RecoStatus {
  all = 'all',
  recommended = 'recommended',
  converted = 'converted',
  archived = 'archived',
  unarchived = 'unarchived'
}

export const CategoryAllMap = [
  { label: 'All Categories', value: 'all' },
  { label: 'Defining & Tracking', value: 'defining_tracking' },
  { label: 'Attracting & Sourcing', value: 'attracting_sourcing' },
  { label: 'Interviewing & Engaging', value: 'interviewing_engaging' },
  { label: 'Onboarding & Advancing', value: 'onboarding_advancing' }
]

export interface RecoNode {
  cursor: string
  node: Reco
}

export interface Reco {
  id: string
  goalId: number
  employerId: string
  employerAssessmentId: string
  employerGoalId: string
  category: string
  title: string
  description: string
  assessmentVersion: string
  assessmentQuestion: string
  assessmentQuestionText: string
  kcUrls: string[]
  status: RecoStatus
  goalActions?: RecoAction[]
}

export type Member = {
  id: string
  name: string
  primaryEmail: string
}

export type MemberEdge = {
  node: Member
}

export type RecoAction = {
  id: string
  title: string
  description: string
  order: number
}
