import { Color } from 'components'

export const CATEGORY_COLOR_5: { [key: string]: string } = {
  'Defining & Tracking': Color.raspberry500,
  'Attracting & Sourcing': Color.eggplant500,
  'Interviewing & Engaging': Color.turquoise500,
  'Onboarding & Advancing': Color.iris500
}
export const CATEGORY_COLOR_4_TEXT: { [key: string]: string } = {
  'Defining & Tracking': Color.raspberry400,
  'Attracting & Sourcing': Color.eggplant400,
  'Interviewing & Engaging': Color.turquoise400,
  'Onboarding & Advancing': Color.iris400
}
export const CATEGORY_COLOR_3: { [key: string]: string } = {
  'Defining & Tracking': Color.raspberry300,
  'Attracting & Sourcing': Color.eggplant300,
  'Interviewing & Engaging': Color.turquoise300,
  'Onboarding & Advancing': Color.iris300
}
export const CATEGORY_COLOR_2: { [key: string]: string } = {
  'Defining & Tracking': Color.raspberry200,
  'Attracting & Sourcing': Color.eggplant200,
  'Interviewing & Engaging': Color.turquoise200,
  'Onboarding & Advancing': Color.iris200
}
export const CATEGORY_COLOR_1_BKGD: { [key: string]: string } = {
  'Defining & Tracking': Color.raspberry100,
  'Attracting & Sourcing': Color.eggplant100,
  'Interviewing & Engaging': Color.turquoise100,
  'Onboarding & Advancing': Color.iris100
}

export const CATEGORY_BAR_GRADIENT: { [key: string]: string } = {
  'Defining & Tracking': 'linear-gradient(270deg, #B83282 50%, #E28DC0 99.61%)',
  'Attracting & Sourcing':
    'linear-gradient(270.04deg, #430DA8 50.04%, #956BDB 94.1%)',
  'Interviewing & Engaging':
    'linear-gradient(270.03deg, #068D8E 50.03%, #6AC2C4 86.68%)',
  'Onboarding & Advancing':
    'linear-gradient(270.03deg, #375FC6 50.03%, #829FE9 92.29%)'
}

export enum BrandColor1 {
  tangerine100 = '#FFF4E3',
  jade100 = '#E2FFF6',
  red100 = '#FFEEEE',
  purple100 = '#FCF1FF',
  sky100 = '#EAFCFF',
  yellow100 = '#FFFAE1'
}

export const GOAL_STATUS_COLOR: { [key: string]: string } = {
  'To Do': BrandColor1.tangerine100,
  'On Track': BrandColor1.jade100,
  'Off Track': BrandColor1.red100,
  Completed: BrandColor1.purple100,
  Archived: BrandColor1.sky100,
  'On Hold': BrandColor1.yellow100
}
