import React from 'react'
import {
  IconButton,
  Paper,
  Typography,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Color } from 'components'
import { GoalCategory } from 'model/actionPlan'
import { CATEGORY_COLOR_4_TEXT, CATEGORY_COLOR_1_BKGD } from 'constants/'
import { KCLinkData } from 'utils/KCUrlPaser'

interface Props {
  content: KCLinkData
  goalCategory: string
}

const KCItem = ({ content, goalCategory }: Props) => {
  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down(1000))

  const { cate, title, type } = content
  const borderColor =
    CATEGORY_COLOR_4_TEXT[
      GoalCategory[goalCategory as keyof typeof GoalCategory]
    ] || Color.border

  return (
    <Paper
      variant='outlined'
      sx={{
        borderColor,
        padding: '16px',
        maxWidth: '100%',
        overflow: 'hidden'
      }}
    >
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={10} sm={8} md={9}>
          {type === 'knowledge-center' && cate && (
            <Typography variant='body14' display='block'>
              {cate}
            </Typography>
          )}
          <Typography variant='body14Light'>{title}</Typography>
        </Grid>
        {!isSmallerScreen && (
          <Grid
            item
            xs={2}
            sm={4}
            md={3}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <IconButton
              sx={{
                height: 40,
                width: 40,
                backgroundColor:
                  CATEGORY_COLOR_1_BKGD[
                    GoalCategory[goalCategory as keyof typeof GoalCategory]
                  ]
              }}
            >
              <ChevronRightIcon fontSize='large' />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}
export default KCItem
