import { gql } from '@apollo/client'

// query

const RecoInput = gql`
  fragment recoInput on Recommendation {
    status
  }
`
const GoalInput = gql`
  fragment goalInput on EmployerGoal {
    customTitle
    activeStatus
    startDate
    endDate
    owner {
      id
      name
      primaryEmail
    }
    members {
      id
      name
      primaryEmail
    }
    note
    isCompleted
    completedAt
  }
`

const GoalPreference = gql`
  fragment goalPreference on UserGoalPreference {
    selectedCategory
    selectedPhase
    selectedLayoutView
  }
`

export const USER_GOAL_PREFERENCE = gql`
  query UserGoalPreference($userId: String!) {
    userGoalPreference(userId: $userId) {
      id
      ...goalPreference
    }
  }
  ${GoalPreference}
`
export const RECOMMENDATION = gql`
  query Recommendation {
    recommendations {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          goalId
          employerId
          employerAssessmentId
          employerGoalId
          category
          title
          description #
          assessmentVersion #
          assessmentQuestion
          assessmentQuestionText
          kcUrls
          status
          goalActions {
            id
            title
            description
            order
          }
          ...recoInput
        }
      }
    }
  }
  ${RecoInput}
`
export const GET_EMPLOYER_STAFF_ACCOUNTS = gql`
  query GetEmployerStaffAccounts(
    $first: Int
    $ehiAccessLevels: [EmployerStaffAccountEhiAccessLevel]
  ) {
    employerStaffAccounts(first: $first, ehiAccessLevels: $ehiAccessLevels) {
      count
      edges {
        node {
          id
          user {
            id
            name
            primaryEmail
          }
        }
      }
    }
  }
`

// mutation

export const UPDATE_EMPLOYER_GOAL = gql`
  mutation UpdateEmployerGoal($input: UpdateEmployerGoalInput!) {
    updateEmployerGoal(input: $input) {
      id
      phase
      ...goalInput
    }
  }
  ${GoalInput}
`

export const UPDATE_EMPLOYER_PREFERENCE = gql`
  mutation UpdateUserGoalPreference($input: UpdateUserGoalPreferenceInput!) {
    updateUserGoalPreference(input: $input) {
      id
      ...goalPreference
    }
  }
  ${GoalPreference}
`

export const SAVE_TO_AP = gql`
  mutation ConvertToGoal($input: ConvertToGoalInput!) {
    convertToGoal(input: $input) {
      id
      status
    }
  }
`

export const ARCHIVE_RECO = gql`
  mutation ArchiveRecommendation($input: ArchiveRecommendationInput!) {
    archiveRecommendation(input: $input) {
      id
      status
    }
  }
`
export const UNARCHIVE_RECO = gql`
  mutation UnarchiveRecommendation($input: UnarchiveRecommendationInput!) {
    unarchiveRecommendation(input: $input) {
      id
      status
    }
  }
`
