import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'

const ImageInfo = styled(Box)({
  display: 'flex',
  borderRadius: '8px',
  padding: 0,
  overflow: 'hidden'
})

interface Props {
  imageUrl?: string | undefined
}

const ImageBlock = styled(Box)(({ imageUrl }: Props) => ({
  width: '50%',
  height: '711px',
  backgroundImage: `url(${imageUrl})`,
  backgroundPositionX: 'center',
  backgroundSize: 'cover'
}))

const RightImageBlock = styled(Box)(({ imageUrl }: Props) => ({
  backgroundImage: `url(${imageUrl})`,
  minWidth: '611px',
  overflow: 'visible',
  backgroundSize: '611px 374px',
  backgroundRepeat: 'no-repeat',
  backgroundPositionY: 'bottom',
  backgroundPositionX: 'right',
  zIndex: '1',
  marginLeft: '-11%',
  marginRight: '2%'
}))

const InfoBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0 150px',
  width: '50%'
})

const LeftInfoBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '56px 0 218px 56px',
  flex: '1'
})

const InfoButton = styled(Button)({
  lineHeight: '24px',
  whiteSpace: 'nowrap'
})

export {
  ImageInfo,
  ImageBlock,
  RightImageBlock,
  InfoBlock,
  LeftInfoBlock,
  InfoButton
}
