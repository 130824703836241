import React from 'react'
import { Divider as MuiDivider, DividerProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const Divider = styled(props => <MuiDivider {...props} />)<DividerProps>(
  () => ({
    width: '100%',
    marginTop: 8,
    marginBottom: 8
  })
)

export default Divider
