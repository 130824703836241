import React, { ReactNode, useContext, useState } from 'react'
import { ActionPlanFilterContext } from 'context/ActionPlan/Filter'
import { MultiSelectMenuTypes } from 'model/actionPlan'
import {
  Box,
  FormControl,
  InputLabel,
  MenuProps,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import { Color } from '@mathison-inc/components'

interface MultiSelectProps {
  children: ReactNode
  label: string
  currentFilter: string[]
  setCurrentFilter: (str: string[]) => void
  handleSelectClear: () => void
  width: string
  variant: MultiSelectMenuTypes
}

const MultiSelectDropdownMenu = ({
  children,
  label,
  currentFilter,
  setCurrentFilter,
  handleSelectClear,
  width: componentWidth,
  variant: selectMenuVariant
}: MultiSelectProps): JSX.Element => {
  const { isSortable } = useContext(ActionPlanFilterContext)
  const [open, setOpen] = useState(false)

  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event
    setCurrentFilter(typeof value === 'string' ? value.split(',') : value)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleClear = () => {
    handleSelectClear()
    handleClose()
  }

  // Input Label
  const formControlStyles = {
    marginTop: 0,
    width: componentWidth,
    '&:hover': {
      '& .MuiInputBase-root.MuiFilledInput-root': {
        backgroundColor: Color.grey400
      }
    },
    '& .MuiInputLabel-root': {
      left: 4
    },
    '& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled': {
      backgroundColor: Color.grey50
    },
    '& .MuiInputLabel-shrink': {
      height: '20px',
      lineHeight: '20px',
      transform: 'translate(12px, 7px) scale(1)'
    },
    '& .MuiInputBase-root .MuiSelect-select': {
      height: '24px',
      lineHeight: '24px',
      paddingTop: '25px',
      paddingBottom: '7px',
      paddingLeft: '16px'
    },
    '& .MuiFilledInput-root': {
      '& .MuiChip-root': {
        height: 'auto',
        background: 'unset',
        '& .MuiChip-label': {
          padding: '0',
          '& .MuiTypography-root': {
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '24px'
          }
        }
      }
    },
    '& .MuiInputBase-root.Mui-focused .MuiSelect-select': {
      paddingTop: '24px',
      paddingBottom: '6px',
      paddingLeft: '15px'
    },
    '& .MuiInputLabel-shrink .MuiTypography-root': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px'
    },
    '& .MuiFilledInput-root .MuiSelect-filled': {
      fontWeight: 700
    }
  }

  // Select menu dropdown wrapper
  const defaultSelectMenuStyles = {
    '& .MuiPaper-root': {
      width: '14%'
    },
    '& .MuiPaper-root .MuiList-root': {
      backgroundColor: Color.white,
      padding: '16px'
    },
    '& .clearBox': {
      margin: '4px 8px'
    },
    '& .clearBox .MuiTypography-root': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  }
  const stackedSelectMenuStyles = {
    '& .MuiPaper-root': {
      minWidth: '14%'
    },
    '& .MuiPaper-root .MuiList-root.MuiMenu-list': {
      padding: '16px 0'
    },
    '& .clearBox': {
      margin: '10px 24px 4px'
    }
  }
  const selectMenuStyles =
    selectMenuVariant === MultiSelectMenuTypes.default
      ? defaultSelectMenuStyles
      : {
          ...defaultSelectMenuStyles,
          ...stackedSelectMenuStyles
        }

  const menuProps = {
    sx: selectMenuStyles,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  }

  return (
    <FormControl variant='filled' sx={formControlStyles} disabled={!isSortable}>
      <InputLabel id={`multi-select-label-${label}`}>
        <Typography variant='body16'>{label}</Typography>
      </InputLabel>
      <Select
        labelId={`multi-select-label-${label}`}
        id={`multi-select-${label}`}
        value={currentFilter as any}
        renderValue={(value: any) => (
          <span>{value?.length < 2 ? value : value.join(', ')}</span>
        )}
        multiple
        MenuProps={menuProps as Partial<MenuProps>}
        onChange={handleChange}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {children}
        <Box className='clearBox'>
          <Typography onClick={handleClear} variant='body14'>
            Clear all
          </Typography>
        </Box>
      </Select>
    </FormControl>
  )
}

export default MultiSelectDropdownMenu
