/* eslint-disable no-console */
import { useCallback, useContext } from 'react'
import { gql, useMutation } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import { AlertContext } from 'context/AlertContext'
import {
  CustomEmployerGoalCreateInput,
  CustomEmployerGoalUpdateInput
} from 'model/customEmployerGoal'
import { GoalResponseFields, EMPLOYER_GOALS } from 'context/ActionPlan/gql'

const GoalInput = gql`
  fragment customGoalInput on EmployerGoal {
    title
    category
    description
    startDate
    endDate
    owner {
      id
      name
      primaryEmail
    }
    members {
      id
      name
      primaryEmail
    }
    note
    isCompleted
    completedAt
    status
    effort
    impact
  }
`

const CREATE_CUSTOM_EMPLOYER_GOAL = gql`
  mutation createCustomEmployerGoal(
    $employerGoalInput: CustomEmployerGoalCreateInput!
  ) {
    createCustomEmployerGoal(input: { employerGoal: $employerGoalInput }) {
      id
      customTitle
      ...customGoalInput
      ...goalResponseFields
    }
  }
  ${GoalInput}
  ${GoalResponseFields}
`

const UPDATE_CUSTOM_EMPLOYER_GOAL = gql`
  mutation updateCustomEmployerGoal(
    $employerGoalInput: CustomEmployerGoalUpdateInput!
  ) {
    updateCustomEmployerGoal(input: { employerGoal: $employerGoalInput }) {
      id
      customTitle
      ...customGoalInput
      ...goalResponseFields
    }
  }
  ${GoalInput}
  ${GoalResponseFields}
`

const useCustomEmployerGoal = () => {
  const { displayAlertMessage } = useContext(AlertContext)

  const { currentAccount } = useContext(MicroServiceContext)

  const updateEmployerGoalsCache = (cache: any, { data }: any) => {
    cache.modify({
      fields: {
        employerGoals() {
          const existingGoals = cache.readQuery({
            query: EMPLOYER_GOALS,
            variables: { employerId: currentAccount.employer.id }
          })
          if (!existingGoals || !existingGoals.employerGoals) {
            return
          }
          const newGoalRef =
            existingGoals.employerGoals.edges &&
            existingGoals.employerGoals.edges.length > 0
              ? {
                  node: data.createCustomEmployerGoal,
                  cursor:
                    existingGoals.employerGoals.edges[
                      existingGoals.employerGoals.edges.length - 1
                    ].cursor
                }
              : {
                  node: data.createCustomEmployerGoal,
                  cursor: null
                }
          cache.writeQuery({
            query: EMPLOYER_GOALS,
            data: {
              employerGoals: {
                edges: [...existingGoals.employerGoals.edges, newGoalRef],
                pageInfo: existingGoals.employerGoals.pageInfo,
                // eslint-disable-next-line no-underscore-dangle
                __typename: existingGoals.employerGoals.__typename
              }
            }
          })
        }
      }
    })
  }

  const [updateCustomEmployerGoalMutation, { error: updateCustomGoalError }] =
    useMutation(UPDATE_CUSTOM_EMPLOYER_GOAL, {
      onCompleted: (data, options) => {
        return { data, options }
      }
    })

  const [createCustomGoalMutation, { error: createCustomGoalError }] =
    useMutation(CREATE_CUSTOM_EMPLOYER_GOAL, {
      onCompleted: (data, options) => {
        return { data, options }
      },
      update: updateEmployerGoalsCache
    })

  const createCustomEmployerGoal = useCallback(
    async (input: CustomEmployerGoalCreateInput) => {
      const response = await createCustomGoalMutation({
        variables: { employerGoalInput: input }
      })
      if (!createCustomGoalError) {
        displayAlertMessage(`Custom goal created`)
        return response
      }
      console.log(
        'createCustomGoalError',
        JSON.stringify(createCustomGoalError, null, 2)
      )
      return false
    },
    [createCustomGoalMutation, createCustomGoalError, displayAlertMessage]
  )

  const updateCustomEmployerGoal = useCallback(
    async (input: CustomEmployerGoalUpdateInput) => {
      const response = await updateCustomEmployerGoalMutation({
        variables: { employerGoalInput: input }
      })
      if (!updateCustomGoalError) {
        displayAlertMessage(`Custom goal updated`)
        return response
      }
      return false
    },
    [
      updateCustomEmployerGoalMutation,
      updateCustomGoalError,
      displayAlertMessage
    ]
  )

  return { createCustomEmployerGoal, updateCustomEmployerGoal }
}

export default useCustomEmployerGoal
