import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { RecommendationsProvider } from 'context/Recommendations'
import { MicroServiceContext } from 'context/MicroService'
import MiniDashboard from './MiniDashboard'
import TabPanel from './CategoryTabPanel'

const Recommendations = () => {
  const { hasAccessToViewDataViz } = useContext(MicroServiceContext)

  return (
    <RecommendationsProvider>
      <Grid container flexDirection='column' wrap='nowrap' minHeight='100%'>
        <Typography variant='h1' marginBottom={1}>
          Recommendations
        </Typography>
        {hasAccessToViewDataViz && <MiniDashboard />}
        <TabPanel />
      </Grid>
    </RecommendationsProvider>
  )
}
export default Recommendations
