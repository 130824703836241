import React, { createContext, useCallback, useMemo } from 'react'
import useSessionStorage from 'hooks/useSessionStorage'

export type CreateGoalProps = {
  title: string
  setTitle: (str: string) => void
  category: string
  setCategory: (cat: string) => void
  description: string
  setDescription: (desc: string) => void
  clearInputCache: () => void
}

export const CreateGoalContext = createContext<CreateGoalProps>({
  title: '',
  setTitle: () => {},
  category: '',
  setCategory: () => {},
  description: '',
  setDescription: () => {},
  clearInputCache: () => {}
})

const CreateGoalContextProvider = ({ children }: any): JSX.Element => {
  const [title, setTitle] = useSessionStorage({
    storageKey: 'create-goal-title',
    fallbackState: ''
  })
  const [category, setCategory] = useSessionStorage({
    storageKey: 'create-goal-category',
    fallbackState: ''
  })
  const [description, setDescription] = useSessionStorage({
    storageKey: 'create-goal-description',
    fallbackState: ''
  })

  const clearInputCache = useCallback(() => {
    setTitle('')
    setCategory('')
    setDescription('')
  }, [setTitle, setCategory, setDescription])

  const createGoalValues = useMemo(
    () => ({
      title,
      setTitle,
      category,
      setCategory,
      description,
      setDescription,
      clearInputCache
    }),
    [
      title,
      setTitle,
      category,
      setCategory,
      description,
      setDescription,
      clearInputCache
    ]
  )
  return (
    <CreateGoalContext.Provider value={createGoalValues}>
      {children}
    </CreateGoalContext.Provider>
  )
}

export default CreateGoalContextProvider
