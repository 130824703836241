import React, { useEffect, useContext, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { MicroServiceContext } from 'context/MicroService'
import { EHIAccess } from 'model/access'
import CreateGoalContextProvider from 'context/CreateGoalContext'
import { ActionPlanContext } from 'context/ActionPlan'
import SidePanel from '../SidePanel'
import PanelContent from './PanelContent'

interface Props {
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean
}

const CreateGoalModule = (props: Props = { disabled: false }) => {
  const {
    currentAccount: { ehiAccess }
  } = useContext(MicroServiceContext)

  const {
    loadSelectedEmployerGoalAndSearchParams,
    setIsCreateGoalSidePanelOpen
  } = useContext(ActionPlanContext)

  const sidePanelRef = useRef<any>(null)

  // open the create custom goal panel on createGoal=true param
  const [searchParams, setSearchParams] = useSearchParams()
  const createGoalParam = searchParams.get('createGoal')

  const openSidePanel = () => {
    sidePanelRef.current?.openSidePanel()
    setIsCreateGoalSidePanelOpen(true)
  }

  const closeSidePanel = (onExitedCallback: any) => {
    sidePanelRef.current?.closeSidePanel(onExitedCallback)
  }

  const beforePanelOnClose: any = () => {
    loadSelectedEmployerGoalAndSearchParams()
  }

  const afterPanelOnClose: any = () => {
    setSearchParams(params => {
      params.delete('createGoal')
      return params
    })
    setIsCreateGoalSidePanelOpen(false)
  }

  const panelOverrideProps = {
    beforePanelOnClose,
    afterPanelOnClose
  }

  const panelContentProps = {
    closeSidePanel,
    afterPanelOnClose
  }

  // open sidepanel if createGoal=true and user has full ehi access
  useEffect(() => {
    if (createGoalParam && ehiAccess === EHIAccess.FULL_EHI_ACCESS) {
      sidePanelRef.current?.openSidePanel()
      setIsCreateGoalSidePanelOpen(true)
    } else if (createGoalParam && ehiAccess !== EHIAccess.FULL_EHI_ACCESS) {
      setSearchParams(params => {
        params.delete('createGoal')
        return params
      })
    }
  }, [
    createGoalParam,
    searchParams,
    setIsCreateGoalSidePanelOpen,
    ehiAccess,
    setSearchParams
  ])

  if (ehiAccess === EHIAccess.FULL_EHI_ACCESS) {
    return (
      <CreateGoalContextProvider>
        <Button
          {...props}
          variant='contained'
          color='primary'
          onClick={openSidePanel}
        >
          <Typography variant='body16'>Create goal</Typography>
        </Button>
        <SidePanel ref={sidePanelRef} {...panelOverrideProps}>
          <PanelContent {...panelContentProps} />
        </SidePanel>
      </CreateGoalContextProvider>
    )
  }
  return null
}

export default CreateGoalModule
