/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, {
  useEffect,
  useRef,
  forwardRef,
  useLayoutEffect,
  memo
} from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import Jodit from './Jodit'
import { config } from './constant'

interface Props {
  id: string
  name: string
  onBlur?: (e: string) => void
  onChange: (e: string) => void
  tabIndex?: number
  value: string
  isActive?: boolean
  onClick: (e?: any) => void
}

type EditorContainerProps = {
  isActive?: boolean
}

// Customize jodit container style, link popup styling refer to ./index.css
const EditorContainer = styled(Box)(({ isActive }: EditorContainerProps) => ({
  border: isActive ? '2px solid #050B0E' : '2px solid #FD404C',
  borderRadius: '8px',
  '& .jodit-container': {
    '&:not(.jodit_inline)': {
      border: 'none'
    },
    '& .jodit-toolbar__box': {
      borderBottom: 'none',
      borderRadius: '8px 8px 0 0',
      padding: '5px 6px',
      backgroundColor: '#F2F3F3',
      '& .jodit-toolbar-editor-collection_mode_horizontal': {
        backgroundImage: 'none',
        '&::after': {
          display: 'none'
        },
        '& .jodit-ui-group': {
          flex: '1 0 auto',
          '& .jodit-toolbar-button': {
            minWidth: '34px',
            height: '34px',
            marginRight: '6px',
            '&:last-child': {
              marginRight: '1px',
              marginLeft: 'auto'
            },
            '& .jodit-toolbar-button__button': {
              minWidth: '34px',
              height: '34px',
              '& .jodit-icon': {
                stroke: 'none',
                width: 'auto',
                height: 'auto'
              }
            }
          },
          '& .jodit-ui-separator': {
            marginRight: '12px',
            borderColor: 'white'
          }
        }
      }
    }
  },
  '& .custom-note-editor p': { margin: 0 }
}))

const JoditEditor = forwardRef(
  (
    { id, name, onBlur, onChange, tabIndex, value, isActive, onClick }: Props,
    ref
  ) => {
    const textArea: any = useRef<HTMLTextAreaElement>(null)

    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(textArea.current)
        } else {
          ref.current = textArea.current
        }
      }
      // eslint-disable-next-line
    }, [textArea])
    useEffect(() => {
      if (textArea.current.uid) {
        textArea.current?.setReadOnly(!isActive)
      }
    }, [isActive])

    useEffect(() => {
      if (textArea && textArea.current) {
        const element = textArea.current
        textArea.current = Jodit.make(element, {
          ...config,
          readonly: !isActive
        })
        textArea.current.workplace.tabIndex = tabIndex || -1

        // adding event handlers
        textArea.current.events.on('blur', (e: any) => {
          onBlur && onBlur(e)
        })
        textArea.current.events.on(
          'change',
          (e: any) => onChange && onChange(e)
        )
        return () => {
          const container = document.getElementsByClassName('jodit--container')
          const popupContainer = document.getElementsByClassName(
            'jodit-popup-container'
          )
          while (container?.length > 0) {
            container[0].parentNode!.removeChild(container[0])
          }
          while (popupContainer?.length > 0) {
            popupContainer[0].parentNode!.removeChild(popupContainer[0])
          }
        }
      }
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
      if (textArea.current && textArea.current?.value !== value) {
        textArea.current.value = value
      }
    }, [textArea, value])

    return (
      <EditorContainer
        id='jodit-container'
        className='jodit-react-container'
        onClick={e => onClick(e)}
        isActive={isActive}
      >
        <textarea
          ref={textArea}
          id={id}
          name={name}
          style={{ display: 'none' }}
        />
      </EditorContainer>
    )
  }
)

JoditEditor.displayName = 'JoditEditor'

export default memo(JoditEditor)
