export type Member = {
  id: string
  name: string
  primaryEmail: string
}

export type MemberEdge = {
  node: Member
}

export interface EmployerGoalNode {
  cursor: string
  node: EmployerGoal
}

export interface EmployerGoal {
  activeStatus: string
  assessmentQuestion: string
  assessmentVersion: string
  category: string
  customTitle: string
  description: string
  employerId: string
  endDate: string
  goalId: number
  id: string
  kcUrls: string[]
  members: Member[]
  onTrack: string
  owner: Member
  phase: string
  scoreDelta: number
  startDate: string
  title: string
  note: string
  isCompleted: boolean
  completedAt: string
  rank: number
  customRank: number
  displayRank: number
  impact: GoalLevel
  effort: GoalLevel
  status: GoalStatus
  employerGoalActions?: EmployerGoalAction[]
  createdType: CreatedType
}

enum CreatedType {
  'mathison' = 'mathison',
  'custom' = 'custom'
}

export type UpdatingEmployerGoal = {
  customTitle?: string
  owner?: Member
  members?: Member[]
  startDate?: string
  endDate?: string
  isCompleted?: boolean
  note?: string
  effort?: GoalLevel
  impact?: GoalLevel
  status?: GoalStatus
  description?: string
  category?: string
}

interface GoalCategoryCounter {
  total: number
}

export interface GoalCounter {
  all: GoalCategoryCounter
  defining_tracking: GoalCategoryCounter
  attracting_sourcing: GoalCategoryCounter
  interviewing_engaging: GoalCategoryCounter
  onboarding_advancing: GoalCategoryCounter
}

export enum GoalCategory {
  'all' = 'All Categories',
  'defining_tracking' = 'Defining & Tracking',
  'attracting_sourcing' = 'Attracting & Sourcing',
  'interviewing_engaging' = 'Interviewing & Engaging',
  'onboarding_advancing' = 'Onboarding & Advancing'
}

export enum GoalLevel {
  low = 'Low',
  medium = 'Medium',
  high = 'High'
}

export enum GoalStatus {
  to_do = 'To Do',
  on_track = 'On Track',
  off_track = 'Off Track',
  completed = 'Completed',
  archived = 'Archived'
}

export const categoryMap = [
  GoalCategory.defining_tracking,
  GoalCategory.attracting_sourcing,
  GoalCategory.interviewing_engaging,
  GoalCategory.onboarding_advancing
]

export const statusMap = [
  GoalStatus.to_do,
  GoalStatus.on_track,
  GoalStatus.off_track,
  GoalStatus.completed,
  GoalStatus.archived
]

export const levelMap = [GoalLevel.high, GoalLevel.low, GoalLevel.medium]

export enum MultiSelectMenuTypes {
  default = 'default-chips',
  member = 'member-list'
}

export type EmployerGoalAction = {
  id: string
  title: string
  description: string
  order: number
}
