import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback
} from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { filterRecosByCategory, sortRecosByStatus } from 'utils/recommendations'
import { Reco, RecoNode, RecoStatus } from 'model/recommendations'
import { GoalCategory } from 'model/actionPlan'
import { MicroServiceContext } from 'context/MicroService'
import { AlertContext } from 'context/AlertContext'
import useRecos from 'hooks/useRecos'
import { useSearchParams } from 'react-router-dom'
import { EMPLOYER_GOALS } from 'context/ActionPlan/gql'
import {
  USER_GOAL_PREFERENCE,
  UPDATE_EMPLOYER_PREFERENCE,
  SAVE_TO_AP,
  ARCHIVE_RECO,
  UNARCHIVE_RECO
} from './gql'

export type Recommendations = {
  selectedReco?: Reco
  recos: RecoNode[]
  setSelectedRecoId: (str: string) => void
  category: string
  setCategory: (cate: GoalCategory) => void
  status: RecoStatus
  setStatus: (status: RecoStatus) => void
  isLoading: boolean
  saveToActionPlan: (recommendationId: string) => void
  archiveReco: (recommendationId: string) => void
  unarchiveReco: (recommendationId: string) => void

  hasAccessToSearchParamGoal: boolean
}

export const RecommendationsContext = createContext<Recommendations>({
  selectedReco: undefined,
  recos: [],
  setSelectedRecoId: () => {},
  setCategory: () => {},
  category: 'attracting_sourcing',
  setStatus: () => {},
  status: RecoStatus.recommended,
  isLoading: true,
  saveToActionPlan: () => {},
  archiveReco: () => {},
  unarchiveReco: () => {},

  hasAccessToSearchParamGoal: false
})

export const RecommendationsProvider = ({ children }: any): JSX.Element => {
  const [selectedRecoId, setSelectedRecoId] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [category, setCategory] = useState<string>('all')
  const [status, setStatus] = useState<RecoStatus>(RecoStatus.recommended)

  const { currentAccount } = useContext(MicroServiceContext)
  const { displayAlertMessage } = useContext(AlertContext)
  const { recommendations } = useRecos()

  const { data: rawUserGoalPreference, previousData } = useQuery(
    USER_GOAL_PREFERENCE,
    { variables: { userId: currentAccount.id } }
  )

  const [updateUserGoalPreference] = useMutation(UPDATE_EMPLOYER_PREFERENCE)
  const [saveToAp, { error: saveToApError }] = useMutation(SAVE_TO_AP, {
    refetchQueries: [
      {
        query: EMPLOYER_GOALS,
        variables: { employerId: currentAccount.employer.id }
      },
      'query employerGoals'
    ]
  })
  const [archiveRecommendation, { error: archiveRecommendationError }] =
    useMutation(ARCHIVE_RECO)
  const [unarchiveRecommendation, { error: unarchiveRecommendationError }] =
    useMutation(UNARCHIVE_RECO)

  const [searchParams, setSearchParams] = useSearchParams()
  const [hasAccessToSearchParamGoal, setHasAccessToSearchParamGoal] =
    useState(true)

  const firstRender = useRef(true)
  const submitUserGoalPreference = (id: string, selectedCategory: string) => {
    updateUserGoalPreference({
      variables: {
        input: {
          userId: id,
          preferences: {
            selectedCategory,

            selectedPhase: 'all',
            selectedLayoutView: 'card'
          }
        }
      }
    })
  }

  useEffect(() => {
    if (rawUserGoalPreference && !previousData) {
      const { userGoalPreference } = rawUserGoalPreference
      if (userGoalPreference) {
        const searchParamId = searchParams.get('id') || ''
        const { selectedCategory } = userGoalPreference
        let isUserGoalPrefBehavior = false
        if (recommendations && searchParamId.length > 0) {
          const edges = [...recommendations.edges]
          const searchParamGoal: RecoNode = edges.find(
            ({ node }) => node.id === searchParamId
          )
          if (searchParamGoal) {
            const cateOverride =
              searchParamGoal.node.category === selectedCategory
                ? selectedCategory
                : 'all'

            setCategory(cateOverride)

            setSelectedRecoId(searchParamGoal.node.id)
          } else {
            isUserGoalPrefBehavior = true
            setHasAccessToSearchParamGoal(false)
          }
        } else {
          isUserGoalPrefBehavior = true
        }
        if (isUserGoalPrefBehavior) {
          if (selectedCategory) {
            setCategory(selectedCategory)
          }
        }
      }
    }
    if (recommendations) {
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [rawUserGoalPreference, recommendations])

  useEffect(() => {
    if (recommendations) {
      const edges = [...recommendations.edges]
      const recos = filterRecosByCategory(edges, category)
      const persistedReco: RecoNode | undefined = recos.find(
        ({ node }) => node.id === selectedRecoId
      )
      if (persistedReco) {
        searchParams.set('id', selectedRecoId)
      } else if (recos.length > 0) {
        const recoId = recos[0].node.id
        setSelectedRecoId(recoId)
        searchParams.set('id', recoId)
      } else {
        setSelectedRecoId('')
        searchParams.delete('id')
      }
      setSearchParams(searchParams, { replace: true })
    }
    if (firstRender.current === true) {
      firstRender.current = false
      return
    }
    submitUserGoalPreference(currentAccount.id, category)
    // eslint-disable-next-line
  }, [category, selectedRecoId])

  let recos: RecoNode[] = useMemo(() => {
    return []
  }, [])

  let selectedReco: Reco | undefined

  if (recommendations) {
    const edges = [...recommendations.edges]
    recos = filterRecosByCategory(edges, category)
    recos = sortRecosByStatus(recos, status)
    selectedReco = (
      recos.find((reco: any) => reco.node.id === selectedRecoId) || recos[0]
    )?.node
  }

  const saveToActionPlan = useCallback(
    async (recommendationId: string) => {
      await saveToAp({
        variables: { input: { recommendationId } }
      })
      if (!saveToApError) displayAlertMessage(`Goal saved`)
    },
    [saveToAp, saveToApError, displayAlertMessage]
  )

  const archiveReco = useCallback(
    async (recommendationId: string) => {
      await archiveRecommendation({
        variables: { input: { recommendationId } }
      })
      if (!archiveRecommendationError)
        displayAlertMessage(`Recommendation archived`)
    },
    [archiveRecommendation, archiveRecommendationError, displayAlertMessage]
  )

  const unarchiveReco = useCallback(
    async (recommendationId: string) => {
      await unarchiveRecommendation({
        variables: { input: { recommendationId } }
      })
      if (!unarchiveRecommendationError)
        displayAlertMessage(`Recommendation unarchived`)
    },
    [unarchiveRecommendation, unarchiveRecommendationError, displayAlertMessage]
  )

  const recommendationsValues = useMemo(
    () => ({
      selectedReco,
      recos,
      setSelectedRecoId,
      unarchiveReco,
      archiveReco,
      saveToActionPlan,
      category,
      setCategory,
      status,
      setStatus,
      isLoading,
      hasAccessToSearchParamGoal
    }),
    [
      category,
      recos,
      status,
      setStatus,
      unarchiveReco,
      archiveReco,
      saveToActionPlan,
      hasAccessToSearchParamGoal,
      isLoading,
      selectedReco
    ]
  )

  return (
    <RecommendationsContext.Provider value={recommendationsValues}>
      {children}
    </RecommendationsContext.Provider>
  )
}
