import React, { useContext } from 'react'
import { ActionPlanFilterContext } from 'context/ActionPlan/Filter'
import { categoryMap, MultiSelectMenuTypes } from 'model/actionPlan'
import { Chip, MenuItem, Typography } from '@mui/material'
import MultiSelectDropdownMenu from '.'
import { defaultMenuItemStyles } from './menuItemStyles'

const CategoryMultiSelect = () => {
  const { categoryFilter, setCategoryFilter, clearCategoryFilter } = useContext(
    ActionPlanFilterContext
  )

  const categorySelectProps = {
    label: 'Category',
    currentFilter: categoryFilter,
    setCurrentFilter: setCategoryFilter,
    handleSelectClear: clearCategoryFilter,
    width: '45%',
    variant: MultiSelectMenuTypes.default
  }

  const selectMap = categoryMap
    ? categoryMap.map((option: any) => {
        return { id: option.toLowerCase().replace(' ', '-'), category: option }
      })
    : []

  return (
    <MultiSelectDropdownMenu {...categorySelectProps}>
      {selectMap.map(({ id, category }) => {
        return (
          <MenuItem value={category} sx={defaultMenuItemStyles} key={id}>
            <Chip
              label={<Typography variant='body14Light'>{category}</Typography>}
            />
          </MenuItem>
        )
      })}
    </MultiSelectDropdownMenu>
  )
}
export default CategoryMultiSelect
