import React, { useContext, useEffect, useState } from 'react'
import { ActionPlanContext } from 'context/ActionPlan'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { InputErrorMessage } from 'components'
import AddIcon from '@mui/icons-material/Add'
import { LoadButton } from '../../styledComponents'
import { TitleInputWithCouter } from '../TitleSection/styledComponents'

const Description = () => {
  const { selectedEmployerGoal, submitEmployerGoal } =
    useContext(ActionPlanContext)
  const [isEditing, setIsEditing] = useState(false)
  const [hasUnsavedChange, setHasUnsavedChange] = useState(false)
  const [descriptionValue, setDescriptionValue] = useState('')
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (selectedEmployerGoal) {
      const { description } = selectedEmployerGoal
      setDescriptionValue(description)
    }
  }, [selectedEmployerGoal])

  const handleEndingChange = () => {
    setIsEditing(false)
    setHasUnsavedChange(false)
    setLoading(false)
  }

  const clickSave = async () => {
    setLoading(true)
    await submitEmployerGoal({ description: descriptionValue }, 'Description')
    handleEndingChange()
  }

  if (!selectedEmployerGoal) return null
  const { createdType, description, status } = selectedEmployerGoal!

  if (createdType === 'custom') {
    return (
      <Grid
        tabIndex={0}
        id='descriptionEditorContainer'
        onBlur={(e: any) => {
          if (
            document
              .getElementById('descriptionEditorContainer')!
              .contains(e.relatedTarget)
          )
            return

          if (descriptionValue !== description) {
            e.stopPropagation()
            setHasUnsavedChange(true)
          }
        }}
      >
        {!isEditing && (
          <Box marginTop='32px' maxWidth='534px'>
            <Typography variant='body16Light' component='p'>
              {description}
            </Typography>
            {String(status) !== 'completed' && (
              <Button
                variant='action'
                startIcon={description ? null : <AddIcon />}
                sx={{ marginTop: '24px', fontSize: '14px', fontWeight: '600' }}
                onClick={() => setIsEditing(true)}
              >
                {description ? 'Edit description' : 'Add Description'}
              </Button>
            )}
          </Box>
        )}
        {isEditing && (
          <Box marginTop='32px'>
            <TitleInputWithCouter
              fullWidth
              onFocus={e => {
                e.stopPropagation()
                setHasUnsavedChange(false)
              }}
              fontSize={16}
              label='Description'
              countLimit={1000}
              autoFocus
              multiline
              error={hasUnsavedChange}
              value={descriptionValue}
              onChange={e => {
                e.stopPropagation()
                setDescriptionValue(e.target.value)
              }}
            />

            <Stack direction='row' justifyContent='space-between' marginTop={2}>
              <div>
                {hasUnsavedChange && (
                  <InputErrorMessage>
                    Your updates won’t be posted until you save your changes
                  </InputErrorMessage>
                )}
                {descriptionValue && descriptionValue.length > 1000 && (
                  <InputErrorMessage>
                    Goal description must be less than 1000 characters
                  </InputErrorMessage>
                )}
              </div>
              <Stack direction='row' spacing={1}>
                <Button
                  variant='underline'
                  aria-label='goal-title-button'
                  onClick={e => {
                    e.preventDefault()
                    setDescriptionValue(description)
                    handleEndingChange()
                  }}
                >
                  Cancel
                </Button>
                <LoadButton
                  variant='contained'
                  aria-label='goal-title-button'
                  size='small'
                  disabled={descriptionValue.length > 1000}
                  loading={loading}
                  loadingPosition='end'
                  onClick={() => {
                    setHasUnsavedChange(false)
                    clickSave()
                  }}
                >
                  Save
                </LoadButton>
              </Stack>
            </Stack>
          </Box>
        )}
      </Grid>
    )
  }
  return description ? (
    <Box paddingTop='48px' maxWidth='534px'>
      <Typography variant='body16Light'>{description}</Typography>
    </Box>
  ) : null
}

export default Description
