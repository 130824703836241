import React, { useContext } from 'react'
import { ActionPlanContext } from 'context/ActionPlan'
import { MicroServiceContext } from 'context/MicroService'
import { EHIAccess } from 'model/access'
import { Box, Typography } from '@mui/material'
import { Color } from '@mathison-inc/components'
import { GoalCategory } from 'model/actionPlan'
import { CATEGORY_COLOR_5 } from 'constants/'
import { GoalTag } from 'components'
import { parseGoalCategoryToFull } from 'utils/actionPlan'
import CustomGoalHeader from 'components/CreateGoalModule/PanelContent/CustomGoalHeader'
import CategorySelect from 'components/CreateGoalModule/PanelContent/CategorySelect'
import TitleSection from './TitleSection'
import Description from './Description'
import StatusSelect from './StatusSelect'

const PanelHeader = () => {
  const {
    currentAccount: { ehiAccess, id: currentAccountId }
  } = useContext(MicroServiceContext)
  const { selectedEmployerGoal } = useContext(ActionPlanContext)
  if (!selectedEmployerGoal) return null
  const { isCompleted, customTitle, title, id, displayRank, category } =
    selectedEmployerGoal
  const getAccessToEditGoal = (goal: any) =>
    ehiAccess === EHIAccess.FULL_EHI_ACCESS ||
    goal.owner?.id === currentAccountId
  const isReadOnly = !getAccessToEditGoal(selectedEmployerGoal) || isCompleted

  return (
    <>
      {selectedEmployerGoal!.createdType === 'custom' && <CustomGoalHeader />}
      <Box
        marginTop={selectedEmployerGoal!.createdType === 'custom' ? 5 : 0}
        marginBottom={5}
        display='flex'
        justifyContent='space-between'
        height='64px'
      >
        <Box display='flex' alignItems='center' width='50%'>
          <Typography
            variant='h4'
            marginRight='16px'
            sx={{
              height: 48,
              width: 48,
              backgroundColor: Color.grey100,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {displayRank}
          </Typography>
          {selectedEmployerGoal!.createdType === 'custom' &&
            String(selectedEmployerGoal!.status) !== 'completed' && (
              <CategorySelect isOnChangeUpdate />
            )}
          {(selectedEmployerGoal!.createdType === 'mathison' ||
            String(selectedEmployerGoal!.status) === 'completed') && (
            <GoalTag
              sx={{
                color:
                  CATEGORY_COLOR_5[
                    GoalCategory[category as keyof typeof GoalCategory]
                  ],
                maxHeight: '48px',
                height: '48px'
              }}
              label={parseGoalCategoryToFull(category)}
            />
          )}
        </Box>
        <StatusSelect />
      </Box>
      <TitleSection
        id={id}
        customTitle={customTitle}
        rawTitle={title}
        readonly={isReadOnly}
      />
      <Description />
    </>
  )
}

export default PanelHeader
