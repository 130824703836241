import React, {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { EHIAccess } from 'model/access'
import { MicroServiceContext } from './MicroService'

export type SideNavProps = {
  hasSideNav: boolean
  setSideNavIfNecessary: (bool: boolean) => void
  browserTabTitle: string
  setBrowserTabTitle: (str: string) => void
}

export const SideNavContext = createContext<SideNavProps>({
  hasSideNav: true,
  setSideNavIfNecessary: () => {},
  browserTabTitle: '',
  setBrowserTabTitle: () => {}
})

export const SideNavProvider = ({ children }: any): JSX.Element => {
  const {
    currentAccount: { ehiAccess },
    flags
  } = useContext(MicroServiceContext)
  const [hasSideNav, setHasSideNav] = useState(true)
  const [browserTabTitle, setBrowserTabTitle] = useState('Mathison')

  const setSideNavIfNecessary = useCallback(
    (isActive: boolean) => {
      if (isActive === false || ehiAccess === EHIAccess.FULL_EHI_ACCESS)
        setHasSideNav(isActive)
    },
    [ehiAccess]
  )

  useLayoutEffect(() => {
    if (ehiAccess !== EHIAccess.FULL_EHI_ACCESS) setHasSideNav(false)
  }, [ehiAccess])

  const sideNavValues = useMemo(
    () => ({
      hasSideNav: hasSideNav && !flags?.enabled_fe_side_navigation,
      setSideNavIfNecessary,
      browserTabTitle,
      setBrowserTabTitle
    }),
    [hasSideNav, setSideNavIfNecessary, browserTabTitle, flags]
  )

  return (
    <SideNavContext.Provider value={sideNavValues}>
      {children}
    </SideNavContext.Provider>
  )
}
