import React, { useContext } from 'react'
import { ActionPlanFilterContext } from 'context/ActionPlan/Filter'
import { statusMap, MultiSelectMenuTypes } from 'model/actionPlan'
import { Chip, MenuItem, Typography } from '@mui/material'
import MultiSelectDropdownMenu from '.'
import { defaultMenuItemStyles } from './menuItemStyles'

const StatusMultiSelect = () => {
  const { statusFilter, setStatusFilter, clearStatusFilter } = useContext(
    ActionPlanFilterContext
  )

  const statusSelectProps = {
    label: 'Status',
    currentFilter: statusFilter,
    setCurrentFilter: setStatusFilter,
    handleSelectClear: clearStatusFilter,
    width: '33%',
    variant: MultiSelectMenuTypes.default
  }

  const selectMap = statusMap
    ? statusMap.map((option: any) => {
        return { id: option.toLowerCase().replace(' ', '-'), status: option }
      })
    : []

  return (
    <MultiSelectDropdownMenu {...statusSelectProps}>
      {selectMap.map(({ id, status }) => {
        return (
          <MenuItem value={status} sx={defaultMenuItemStyles} key={id}>
            <Chip
              label={<Typography variant='body14Light'>{status}</Typography>}
            />
          </MenuItem>
        )
      })}
    </MultiSelectDropdownMenu>
  )
}
export default StatusMultiSelect
