/* eslint-disable no-plusplus */
import moment, { Moment } from 'moment'
import { EmployerGoalNode, GoalCounter, GoalCategory } from 'model/actionPlan'

export const toMomentDate = (date: string | Moment | null, end?: boolean) => {
  if (end === true) {
    return moment(date, ['MM/YYYY', 'MMMM YYYY']).endOf('month')
  }
  return moment(date, ['MM/YYYY', 'MMMM YYYY'])
}

export const validateMomentDate = (date: string) => {
  const momentCurrentDate = moment()
  if (
    moment(date, 'MM/YYYY').isValid() &&
    momentCurrentDate.isSameOrBefore(moment(date, 'MM/YYYY'), 'month')
  ) {
    return true
  }
  if (
    moment(date, 'MMMM YYYY').isValid() &&
    momentCurrentDate.isSameOrBefore(moment(date, 'MMMM YYYY'), 'month')
  ) {
    return true
  }
  return false
}

export const parseGoalCategory = (category: string) => {
  const letters = category?.split('_')
  return `${letters[0].charAt(0).toUpperCase()}&${letters[1]
    ?.charAt(0)
    .toUpperCase()}`
}

export const parseGoalCategoryToFull = (category: string) => {
  const letters = category?.split('_')
  return `${letters[0].toUpperCase()} & ${letters[1]?.toUpperCase()}`
}

export const goalTitleCache = {
  getItem: (id?: string) => localStorage.getItem(`goal-title-${id}`),
  setItem: (value: string, id?: string) =>
    localStorage.setItem(`goal-title-${id}`, value),
  removeItem: (id?: string) => localStorage.removeItem(`goal-title-${id}`)
}

export const goalNoteCache = {
  getItem: (id?: string) => localStorage.getItem(`goal-note-${id}`),
  setItem: (value: string, id?: string) =>
    localStorage.setItem(`goal-note-${id}`, value),
  removeItem: (id?: string) => localStorage.removeItem(`goal-note-${id}`)
}

export const getGoalCount = (goals: EmployerGoalNode[]): GoalCounter => {
  const count = {
    all: { total: goals.length, title: GoalCategory.all },
    defining_tracking: {
      total: 0,
      title: GoalCategory.defining_tracking
    },
    attracting_sourcing: {
      total: 0,
      title: GoalCategory.attracting_sourcing
    },
    interviewing_engaging: {
      total: 0,
      title: GoalCategory.interviewing_engaging
    },
    onboarding_advancing: {
      total: 0,
      title: GoalCategory.onboarding_advancing
    }
  }
  goals.forEach(({ node }) => {
    if (node.category === 'defining_tracking') {
      count.defining_tracking.total++
    } else if (node.category === 'attracting_sourcing') {
      count.attracting_sourcing.total++
    } else if (node.category === 'interviewing_engaging') {
      count.interviewing_engaging.total++
    } else if (node.category === 'onboarding_advancing') {
      count.onboarding_advancing.total++
    }
  })
  return count
}
