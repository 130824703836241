import React from 'react'
import { Box } from '@mui/material'
import { Color } from '@mathison-inc/components'
import { GoalTag, Divider } from 'components'

const CustomGoalHeader = () => (
  <>
    <Box paddingBottom={1.5}>
      <GoalTag
        sx={{
          backgroundColor: Color.grey600,
          color: Color.white,
          maxHeight: '32px',
          height: '32px'
        }}
        label={'Custom Goal'.toUpperCase()}
      />
    </Box>
    <Divider />
  </>
)

export default CustomGoalHeader
