/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Table, TableRow, Typography } from '@mui/material'
import { INITIAL_EMPLOYER_ASSESSMENT_HISTORY } from 'context/Dashboard'
import { orderProgressByCategory } from 'utils/dashboardUtils'
import {
  Category,
  EmployerQuarterHistory,
  EpLatestAssessments
} from 'model/assessment'
import MiniRow from '../MiniRow'
import { MiniCell, BottomCell, GraphTicks } from '../styledComponents'

interface IProps {
  employerAssessmentHistory: EpLatestAssessments
}

const MiniDashboard = ({ employerAssessmentHistory }: IProps) => {
  const emptyCategoryScores =
    INITIAL_EMPLOYER_ASSESSMENT_HISTORY.latestAssessment
      ?.employerAssessmentCategories

  const currentScore: EmployerQuarterHistory | null =
    employerAssessmentHistory.latestAssessment

  const categoryScores: Category[] | undefined = currentScore
    ? currentScore.employerAssessmentCategories
    : emptyCategoryScores

  const orderedCategoryScores: Category[] | undefined =
    orderProgressByCategory(categoryScores)

  return (
    <Table sx={{ marginTop: '8px' }}>
      <TableRow
        sx={{
          display: 'flex',
          height: '44px',
          borderBottom: '1px solid #ACADAF'
        }}
      >
        <MiniCell width='22.5%' />
        <MiniCell width='43.5%' />
        <MiniCell
          width='12.5%'
          sx={{
            textAlign: 'center',
            borderRight: '1px solid #E5E5E5',
            borderLeft: '1px solid #E5E5E5'
          }}
        >
          <Typography variant='subtitle2' textAlign='center'>
            TOTAL SCORE
          </Typography>
        </MiniCell>
        <MiniCell width='21.5%' sx={{ textAlign: 'center' }}>
          <Typography variant='subtitle2' textAlign='center'>
            TOTAL ACTIVE GOALS
          </Typography>
        </MiniCell>
      </TableRow>
      {orderedCategoryScores &&
        orderedCategoryScores.map((category, idx) => (
          <MiniRow
            key={idx}
            goalCategory={category.name.replace(' & ', '_').toLowerCase()}
            score={category.acquiredScore}
          />
        ))}
      <TableRow sx={{ display: 'flex', height: '18px' }}>
        <BottomCell width='22.5%' />
        <BottomCell
          width='43.5%'
          sx={{
            background:
              'repeating-linear-gradient(90deg, #E5E5E5, #E5E5E5 1px, white 0px, white 20%)'
          }}
        />
        <BottomCell
          width='12.5%'
          sx={{
            borderRight: '1px solid #E5E5E5',
            borderLeft: '1px solid #E5E5E5'
          }}
        />
        <BottomCell width='21.5%' />
      </TableRow>
      <TableRow sx={{ display: 'flex', height: '18px' }}>
        <BottomCell width='22.2%' />
        <BottomCell
          width='43.5%'
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <GraphTicks>0</GraphTicks>
          <GraphTicks>5</GraphTicks>
          <GraphTicks>10</GraphTicks>
          <GraphTicks>15</GraphTicks>
          <GraphTicks>20</GraphTicks>
          <GraphTicks>25</GraphTicks>
        </BottomCell>
      </TableRow>
    </Table>
  )
}
export default MiniDashboard
