import { Color } from 'components'

// Each select MenuItem option - default chip
export const defaultMenuItemStyles = {
  cursor: 'default',
  display: 'inline-block',
  margin: '0 8px 12px 0',
  padding: '0',
  height: '32px',
  '&.MuiButtonBase-root': {
    backgroundColor: 'transparent'
  },
  '&:hover .MuiChip-root': { backgroundColor: Color.grey400 },
  '&.Mui-focusVisible  .MuiChip-root': {
    backgroundColor: Color.grey400
  },
  '&.Mui-selected': {
    '&:hover': {
      backgroundColor: 'transparent',
      '& .MuiChip-root': { backgroundColor: '#333' }
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'transparent',
      '& .MuiChip-root': {
        backgroundColor: '#333'
      }
    },
    '& .MuiChip-root': {
      background: Color.black,
      color: Color.white
    },
    '& .MuiTypography-root': {
      fontWeight: '600'
    }
  },
  '& .MuiChip-root': {
    backgroundColor: Color.grey100,
    color: Color.main,
    cursor: 'pointer',
    transitionProperty: 'background-color, box-shadow, color'
  },
  '& .MuiTouchRipple-root ': {
    backgroundColor: 'transparent'
  }
}

// Each select MenuItem option - member avatar with name
export const memberMenuItemStyles = {
  ...defaultMenuItemStyles,
  cursor: 'pointer',
  display: 'flex',
  height: '44px',
  margin: 0,
  padding: '0 16px',
  '&.MuiButtonBase-root': {
    borderRadius: 0
  },
  '&:hover': { backgroundColor: Color.grey50 },
  '&.Mui-focusVisible': {
    backgroundColor: Color.grey50
  },
  '&.Mui-selected': {
    backgroundColor: Color.grey200,
    '&:hover': {
      backgroundColor: Color.grey100
    },
    '&.Mui-focusVisible': {
      backgroundColor: Color.grey100
    }
  },
  '& .MuiAvatar-root .MuiTypography-root': {
    fontFamily: 'IBM Plex Sans',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px'
  },
  '& .MuiTypography-root.memberNameTypgraphy': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '12px'
  }
}
