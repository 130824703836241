import React, { useContext } from 'react'
import { EHIAccess } from 'model/access'
import { MicroServiceContext } from 'context/MicroService'
import { ActionPlanFilterContext } from 'context/ActionPlan/Filter'
import { Box, Stack } from '@mui/material'
import { SwitchButton } from 'components'
import CategoryMultiSelect from './MultiSelectDropdownMenu/CategoryMultiSelect'
import StatusMultiSelect from './MultiSelectDropdownMenu/StatusMultiSelect'
import OwnerMultiSelect from './MultiSelectDropdownMenu/OwnerMultiSelect'

const UserControlsHeader = () => {
  const {
    currentAccount: { ehiAccess }
  } = useContext(MicroServiceContext)
  const { clearAllFilters, isSortable, setIsSortable, setGridKey } = useContext(
    ActionPlanFilterContext
  )

  return (
    <Stack direction='row' justifyContent='space-between'>
      <Stack direction='row' spacing={1.5} width='50%'>
        <CategoryMultiSelect />
        <StatusMultiSelect />
        <OwnerMultiSelect />
      </Stack>
      {ehiAccess === EHIAccess.FULL_EHI_ACCESS && (
        <Box display='flex' alignItems='center'>
          <SwitchButton
            isChecked={!isSortable}
            setIsChecked={bool => {
              setIsSortable(!bool)
              setGridKey((key: number) => key + 1)
              // this refreshes the grid to an unsorted, unfiltered state
              if (bool) {
                clearAllFilters()
              }
            }}
          >
            CUSTOM SORTING
          </SwitchButton>
        </Box>
      )}
    </Stack>
  )
}

export default UserControlsHeader
