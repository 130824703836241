import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { Divider } from 'components'

interface Props {
  sectionTitle?: string
  hasDivider?: boolean
}

const ExpandedRecoSectionTitle = styled(
  ({ sectionTitle, hasDivider }: Props) => (
    <Box>
      <Typography
        variant='subtitle2'
        sx={{ marginTop: 5, textAlign: 'left', textTransform: 'uppercase' }}
      >
        {sectionTitle}
      </Typography>
      {hasDivider && <Divider />}
    </Box>
  )
)()

ExpandedRecoSectionTitle.defaultProps = {
  hasDivider: true
}

export { ExpandedRecoSectionTitle }
