/* eslint-disable react/require-default-props */
import React, { useState, useContext, memo, useMemo } from 'react'
import { Grid, Typography, Button, Box, Stack } from '@mui/material'
import { ActionPlanContext } from 'context/ActionPlan'
import { goalTitleCache } from 'utils/actionPlan'
import { InputErrorMessage } from 'components'
import { TitleInputWithCouter } from './styledComponents'
import { LoadButton } from '../../styledComponents'

interface Props {
  id: string
  rawTitle: string
  customTitle: string | null
  readonly?: boolean
}

const TitleSection = ({ id, rawTitle, customTitle, readonly }: Props) => {
  const [title, setTitle] = useState(
    goalTitleCache.getItem(id) || customTitle || rawTitle || ''
  )
  const [isEditingGoal, setIsEditingGoal] = useState(false)
  const [isLess5, setIsLess5] = useState(false)
  const [hasUnsavedChange, setHasUnsavedChange] = useState(false)
  const { submitEmployerGoal, selectedEmployerGoal } =
    useContext(ActionPlanContext)
  const unsavedTitle = goalTitleCache.getItem(id)
  const [loading, setLoading] = useState(false)
  const { createdType } = selectedEmployerGoal!

  useMemo(() => {
    setIsLess5(false)
    setTitle(unsavedTitle || customTitle || rawTitle || '')
    if (!unsavedTitle) {
      setHasUnsavedChange(false)
      setIsEditingGoal(false)
    } else {
      setIsEditingGoal(true)
      setHasUnsavedChange(true)
    }
    // eslint-disable-next-line
  }, [id])

  const handleEndingChange = () => {
    setIsEditingGoal(false)
    setHasUnsavedChange(false)
    setIsLess5(false)
    goalTitleCache.removeItem(id)
    setLoading(false)
  }

  const clickSave = async () => {
    setHasUnsavedChange(false)
    if (title.length < 5) {
      setIsLess5(true)
    } else {
      await submitEmployerGoal({ customTitle: title }, 'Goal title')
      handleEndingChange()
    }
    setLoading(false)
  }

  return (
    <Grid
      container
      tabIndex={0}
      id='titleEditorContainer'
      onBlur={(e: any) => {
        if (
          document
            .getElementById('titleEditorContainer')!
            .contains(e.relatedTarget)
        )
          return

        if (title && title !== customTitle) {
          goalTitleCache.setItem(title, id)
          setIsLess5(false)
          setHasUnsavedChange(true)
        }
      }}
    >
      {readonly ? (
        <Typography variant='h4' marginTop={4}>
          {customTitle || rawTitle}
        </Typography>
      ) : (
        <div style={{ width: '100%' }}>
          {!isEditingGoal && (
            <Box>
              <Typography variant='h2' marginBottom={2}>
                {customTitle || rawTitle}
              </Typography>

              <Button
                variant='action'
                sx={{ marginTop: '8px', fontSize: '14px', fontWeight: '600' }}
                onClick={() => setIsEditingGoal(true)}
              >
                Edit title
              </Button>
            </Box>
          )}
          {isEditingGoal && (
            <Box>
              <TitleInputWithCouter
                fullWidth
                label='Title'
                onFocus={e => {
                  e.stopPropagation()
                  setHasUnsavedChange(false)
                }}
                countLimit={300}
                multiline
                error={
                  !title || title.length >= 301 || isLess5 || hasUnsavedChange
                }
                value={title}
                onChange={e => {
                  setIsLess5(false)
                  e.stopPropagation()
                  setTitle(e.target.value)
                }}
              />
              {createdType === 'mathison' && (
                <>
                  {!title && (
                    <InputErrorMessage>
                      Goal must have a title
                    </InputErrorMessage>
                  )}
                  {title && title.length > 300 && (
                    <InputErrorMessage>
                      Goal title must be less than 300 characters
                    </InputErrorMessage>
                  )}
                  {isLess5 && (
                    <InputErrorMessage>
                      Goal title must be at least 5 characters
                    </InputErrorMessage>
                  )}
                  {hasUnsavedChange && (
                    <InputErrorMessage>
                      Your updates won’t be posted until you save your changes
                    </InputErrorMessage>
                  )}
                </>
              )}
              <Stack
                direction='row'
                justifyContent='space-between'
                marginTop={2}
              >
                {createdType === 'mathison' ? (
                  <Button
                    variant='underline'
                    onClick={e => {
                      e.preventDefault()
                      setTitle(rawTitle)
                      submitEmployerGoal({ customTitle: '' }, 'Goal title')
                      handleEndingChange()
                    }}
                  >
                    Revert to original goal
                  </Button>
                ) : (
                  <div>
                    {!title && (
                      <InputErrorMessage>
                        Goal must have a title
                      </InputErrorMessage>
                    )}
                    {title && title.length > 300 && (
                      <InputErrorMessage>
                        Goal title must be less than 300 characters
                      </InputErrorMessage>
                    )}
                    {isLess5 && (
                      <InputErrorMessage>
                        Goal title must be at least 5 characters
                      </InputErrorMessage>
                    )}
                    {hasUnsavedChange && (
                      <InputErrorMessage>
                        Your updates won’t be posted until you save your changes
                      </InputErrorMessage>
                    )}
                  </div>
                )}
                <Stack direction='row' spacing={1}>
                  <Button
                    variant='underline'
                    aria-label='goal-title-button'
                    onClick={e => {
                      e.preventDefault()
                      setTitle(customTitle || rawTitle)
                      handleEndingChange()
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadButton
                    variant='contained'
                    aria-label='goal-title-button'
                    size='small'
                    disabled={!title || title.length >= 301}
                    loading={loading}
                    loadingPosition='end'
                    onClick={() => {
                      setLoading(true)
                      clickSave()
                    }}
                  >
                    Save
                  </LoadButton>
                </Stack>
              </Stack>
            </Box>
          )}
        </div>
      )}
    </Grid>
  )
}
export default memo(TitleSection)
