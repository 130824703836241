import React, { useState, useEffect } from 'react'

interface Props {
  storageKey: string
  fallbackState: any
}

const useSessionStorage = ({ storageKey, fallbackState }: Props) => {
  const [value, setValue] = useState(
    JSON.parse(sessionStorage.getItem(storageKey) as any) ?? fallbackState
  )

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}

export default useSessionStorage
