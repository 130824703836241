import React, { useContext, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { RecommendationsContext } from 'context/Recommendations'
import { GoalTag } from 'components'
import { GoalCategory } from 'model/actionPlan'
import { CATEGORY_COLOR_1_BKGD, CATEGORY_COLOR_5 } from 'constants/'
import { parseGoalCategoryToFull } from 'utils/actionPlan'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import RecoHeaderButton from './RecoHeaderButton'

const HeaderActions = () => {
  const { selectedReco, saveToActionPlan, archiveReco, unarchiveReco } =
    useContext(RecommendationsContext)
  const { category, id, status } = selectedReco!
  const [archiveText, setArchiveText] = useState('Archive')

  useEffect(() => {
    if (status === 'archived') {
      setArchiveText('Unarchive')
    } else {
      setArchiveText('Archive')
    }
  }, [status])

  return (
    <Grid
      container
      marginBottom='48px'
      alignItems='center'
      justifyContent='space-between'
    >
      <Grid item xs={6}>
        <GoalTag
          sx={{
            backgroundColor:
              CATEGORY_COLOR_1_BKGD[
                GoalCategory[category as keyof typeof GoalCategory]
              ],
            color:
              CATEGORY_COLOR_5[
                GoalCategory[category as keyof typeof GoalCategory]
              ],
            maxHeight: '44px',
            height: '44px'
          }}
          label={parseGoalCategoryToFull(category)}
        />
      </Grid>
      <Grid item xs={6} display='flex' justifyContent='flex-end'>
        <Grid paddingRight={1}>
          <RecoHeaderButton
            disabled={status === 'converted'}
            hidden={status === 'converted'}
            variant='action'
            onClick={() => {
              if (status === 'archived') {
                unarchiveReco(id)
              } else {
                archiveReco(id)
              }
            }}
          >
            <Typography variant='body14'>{archiveText}</Typography>
          </RecoHeaderButton>
        </Grid>
        <RecoHeaderButton
          variant='contained'
          hidden={status === 'converted' || status === 'archived'}
          disabled={status === 'converted' || status === 'archived'}
          onClick={() => {
            saveToActionPlan(id)
          }}
        >
          <Typography variant='body14'>Save to my Action Plan</Typography>
          <StarBorderRoundedIcon
            sx={{ marginLeft: '10px', fontSize: '1.4rem' }}
          />
        </RecoHeaderButton>
      </Grid>
    </Grid>
  )
}

export default HeaderActions
