import React, { useContext } from 'react'
import { Stack, Typography } from '@mui/material'
import { SharedActionPlanContext } from 'context/SharedActionPlan'
import CreateGoalModule from 'components/CreateGoalModule'

const Header = () => {
  const { isLoading } = useContext(SharedActionPlanContext)

  return (
    <Stack direction='row' justifyContent='space-between'>
      <Typography variant='h1'>Action Plan</Typography>
      {!isLoading && <CreateGoalModule />}
    </Stack>
  )
}

export default Header
