import React, { useContext, useEffect, useState } from 'react'
import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  MenuProps,
  Select,
  Typography
} from '@mui/material'
import { GoalCategory, categoryMap } from 'model/actionPlan'
import { Color } from 'components'
import { defaultMenuItemStyles } from 'pages/ActionPlan/EmployerGoalDataGrid/UserControlsHeader/MultiSelectDropdownMenu/menuItemStyles'
import { CreateGoalContext } from 'context/CreateGoalContext'
import { ActionPlanContext } from 'context/ActionPlan'

// Select menu dropdown wrapper
const defaultSelectMenuStyles = {
  '& .MuiPaper-root': {
    width: '14%'
  },
  '& .MuiPaper-root .MuiList-root': {
    backgroundColor: Color.white,
    padding: '16px 16px 4px'
  },
  '& .clearBox': {
    margin: '4px 8px'
  },
  '& .clearBox .MuiTypography-root': {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}

const menuProps = {
  sx: defaultSelectMenuStyles,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
}

interface Props {
  // eslint-disable-next-line react/require-default-props
  isOnChangeUpdate?: boolean
}

const CategorySelect = ({ isOnChangeUpdate = false }: Props) => {
  const { category: categoryValue, setCategory: setCategoryValue } =
    useContext(CreateGoalContext)
  const { selectedEmployerGoal, submitEmployerGoal } =
    useContext(ActionPlanContext)
  const selectMap = categoryMap
    ? categoryMap.map((option: any) => {
        return {
          id: option.toLowerCase().replace(' & ', '_'),
          category: option
        }
      })
    : []
  const [categoryOnChangeValue, setCategoryOnChangeValue] = useState('')

  useEffect(() => {
    if (selectedEmployerGoal) {
      setCategoryOnChangeValue(selectedEmployerGoal?.category)
    }
  }, [selectedEmployerGoal, setCategoryOnChangeValue])

  return (
    <FormControl
      variant='filled'
      sx={{
        marginTop: '0',
        minWidth: '10rem',
        '& .MuiInputBase-root .MuiSelect-select': { paddingLeft: '16px' }
      }}
    >
      <InputLabel
        id='category-select-label'
        sx={{
          left: '5px',
          '&.MuiInputLabel-shrink': { fontWeight: '400' }
        }}
      >
        <Typography variant='body16'>
          Category
          {!isOnChangeUpdate && (
            <Typography variant='body16' color={Color.error}>
              *
            </Typography>
          )}
        </Typography>
      </InputLabel>
      <Select
        value={!isOnChangeUpdate ? categoryValue : categoryOnChangeValue}
        label='Category'
        labelId='category-select-label'
        renderValue={(category: any) => {
          return (
            <Typography variant='body16'>
              {GoalCategory[category as keyof typeof GoalCategory]}
            </Typography>
          )
        }}
        displayEmpty
        MenuProps={menuProps as Partial<MenuProps>}
        inputProps={{ 'aria-label': 'Without label' }}
        onChange={e => {
          const newCategoryValue = e.target.value as keyof typeof GoalCategory
          if (!isOnChangeUpdate) {
            setCategoryValue(newCategoryValue)
          } else {
            setCategoryOnChangeValue(newCategoryValue)
            submitEmployerGoal({ category: newCategoryValue }, 'Category')
          }
        }}
      >
        {selectMap.map(({ id, category }) => {
          return (
            <MenuItem value={id} key={id} sx={defaultMenuItemStyles}>
              <Chip
                label={
                  <Typography variant='body14Light'>{category}</Typography>
                }
              />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default CategorySelect
