import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import useCustomEmployerGoal from 'hooks/useCustomEmployerGoal'
import { CustomEmployerGoalCreateInput } from 'model/customEmployerGoal'
import { Color } from '@mathison-inc/components'
import { LoadButton } from 'pages/ActionPlan/SidePanel/styledComponents'
import { CreateGoalContext } from 'context/CreateGoalContext'
import { ActionPlanContext } from 'context/ActionPlan'
import PanelHeader from 'pages/ActionPlan/SidePanel/PanelHeader'
import PanelDetails from 'pages/ActionPlan/SidePanel/PanelDetails'
import CustomGoalHeader from './CustomGoalHeader'
import CategorySelect from './CategorySelect'
import { PanelTooltip, TextFieldInput } from './styledComponents'

const PanelContent = ({ closeSidePanel, afterPanelOnClose }: any) => {
  const { createCustomEmployerGoal } = useCustomEmployerGoal()
  const {
    title,
    setTitle,
    category,
    description,
    setDescription,
    clearInputCache
  } = useContext(CreateGoalContext)
  const {
    loadSelectedEmployerGoalAndSearchParams,
    setIsCreateGoalSidePanelOpen,
    selectedEmployerGoal
  } = useContext(ActionPlanContext)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [isUpdateGoalView, setIsUpdateGoalView] = useState(false)

  const checkError = useCallback(() => {
    switch (true) {
      case !category:
      case !title:
        setError('no-title-no-category')
        break
      case title.length < 5:
        setError('title-less-5')
        break
      case title.length > 300:
        setError('title-greater-300')
        break
      case description && description.length > 1000:
        setError('desc-greater-1000')
        break
      default:
        setError('')
    }
  }, [title, category, description])

  useEffect(() => {
    checkError()
  }, [checkError])

  useEffect(() => {
    if (selectedEmployerGoal && isUpdateGoalView) {
      clearInputCache()
    }
  }, [selectedEmployerGoal, isUpdateGoalView, clearInputCache])

  const errorMessages: any = {
    'no-title-no-category': (
      <>
        You need to <strong>define the category</strong>, and{' '}
        <strong>add a title</strong> before creating this goal
      </>
    ),
    'title-less-5': 'Goal title must be at least 5 characters',
    'title-greater-300': 'Goal title must be less than 300 characters',
    'desc-less-5': 'Description must be at least 5 characters',
    'desc-greater-1000': 'Description must be less than 1000 characters'
  }

  const handleSubmit = async () => {
    if (Boolean(error) === false) {
      setIsLoading(true)
      const newGoal: CustomEmployerGoalCreateInput = {
        title,
        category,
        ...(description && { description })
      }
      const response: any = await createCustomEmployerGoal(newGoal)
      loadSelectedEmployerGoalAndSearchParams(
        response?.data?.createCustomEmployerGoal?.id
      )
      setIsUpdateGoalView(true)
    }
  }

  if (selectedEmployerGoal && isUpdateGoalView) {
    return (
      <>
        <PanelHeader />
        <PanelDetails />
      </>
    )
  }
  return (
    <>
      <CustomGoalHeader />
      <Box
        marginTop={5}
        marginBottom={5}
        display='flex'
        justifyContent='space-between'
        height='64px'
      >
        <CategorySelect />
      </Box>
      <TextFieldInput
        countLimit={300}
        label={
          <Typography variant='body20Light' lineHeight='32px'>
            Title
            <Typography variant='body20Light' color={Color.error}>
              *
            </Typography>
          </Typography>
        }
        value={title}
        variant='filled'
        type='title'
        multiline
        sx={{ marginTop: 2 }}
        onChange={e => {
          e.stopPropagation()
          const newTitle = e.target.value
          setTitle(newTitle)
        }}
      />
      <TextFieldInput
        countLimit={1000}
        label={
          <Typography variant='body20Light' lineHeight='32px'>
            Description
          </Typography>
        }
        value={description}
        variant='filled'
        type='description'
        multiline
        minRows={2}
        sx={{
          marginTop: '24px'
        }}
        onChange={e => {
          e.stopPropagation()
          const newDesc = e.target.value
          setDescription(newDesc)
        }}
      />
      <Stack direction='row' justifyContent='flex-end' marginTop={5}>
        <Stack direction='row' spacing={3.5}>
          <Button
            variant='underline'
            aria-label='cancel-create-goal'
            onClick={e => {
              e.preventDefault()
              closeSidePanel()
              afterPanelOnClose()
              setIsCreateGoalSidePanelOpen(false)
            }}
          >
            <Typography variant='body16'>Cancel</Typography>
          </Button>
          <PanelTooltip title={error && <Box>{errorMessages[error]}</Box>}>
            <Box>
              <LoadButton
                variant='contained'
                aria-label='create-goal-button'
                disabled={Boolean(error)}
                loading={isLoading}
                loadingPosition='end'
                loadingWidth={136}
                onClick={handleSubmit}
              >
                Create goal
              </LoadButton>
            </Box>
          </PanelTooltip>
        </Stack>
      </Stack>
    </>
  )
}

export default PanelContent
