/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import createApolloClient from 'utils/createApolloClient'
import { SideNavProvider } from 'context/SideNavContext'
import RouteProvider from './routes'

const App = () => {
  const { maestroClient } = useContext(MicroServiceContext)

  return (
    <ApolloProvider
      client={
        (window as any).__POWERED_BY_QIANKUN__
          ? maestroClient
          : createApolloClient()
      }
    >
      <Router>
        <SideNavProvider>
          <RouteProvider />
        </SideNavProvider>
      </Router>
    </ApolloProvider>
  )
}

export default App
