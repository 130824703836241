import HeroImgOffice from 'assets/images/hero/404officeimg.webp'
import HeroImgLaptop from 'assets/images/hero/laptopbreak.webp'
import HeroImgPng from 'assets/images/hero/laptopbreakfull.webp'

const serviceUrl = process.env.REACT_APP_URL as string

export const HERO = {
  title: 'ERROR 404 • page not found',
  message: 'It looks like this goal walked away.',
  image: `${serviceUrl}${HeroImgOffice}`
}

export const HERO_W_NO_EI_ACCESS = {
  desc: 'This goal no longer exists or you may not have access. Email your administrator for support. In the meantime, can we recommend some articles you may find helpful?',
  btnTitle: 'Go to the Knowledge Center',
  btnUrl: '/knowledge-center',
  linkTitle: '',
  linkUrl: ''
}

export const HERO_W_ZERO_GOALS = {
  title: '',
  message: 'It looks like this goal took a coffee break.',
  image: `${serviceUrl}${HeroImgLaptop}`,
  desc: 'It looks like you have no goals, or if you just took your Assessment, a new set of goals is loading and will appear here shortly. Try refreshing or come back in a few minutes.',
  btnTitle: 'Go to the Knowledge Center',
  btnUrl: '/knowledge-center',
  linkTitle: '',
  linkUrl: ''
}

export const HERO_W_NO_EMPLOYER_GOALS = {
  title: '',
  message:
    'You can start creating goals by clicking the "Create Goal" button located above. You will also received recommendations once the Equity Index Assessment is finished — curated by us at Mathison — to get you and the team moving in the right direction. You can now create your own goals without taking the EI assessment.',
  image: `${serviceUrl}${HeroImgPng}`,
  desc: 'Already taken the Assessment? It may still be loading. Or, your DEI team hasn’t saved any goals to the Action Plan just yet. Give it some time and check in soon.',
  btnTitle: 'Go to the Knowledge Center',
  btnUrl: '/knowledge-center',
  linkTitle: 'Visit our Help Center',
  linkUrl:
    'https://mathison.notion.site/Mathison-Platform-Guide-3cec3a89018843b0874cbdb3bcc0fb99'
}

export const HERO_W_BASIC_EI_ACCESS = {
  desc: 'This goal no longer exists or you may not have access. Would you like to view your other goals instead?',
  btnTitle: 'View your goals',
  btnUrl: '/action-plan/recommendations',
  linkTitle: '',
  linkUrl: ''
}
