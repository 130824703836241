import React, { useContext } from 'react'
import { ActionPlanFilterContext } from 'context/ActionPlan/Filter'
import { MultiSelectMenuTypes } from 'model/actionPlan'
import { ActionPlanContext } from 'context/ActionPlan'
import { MenuItem, Stack, Typography } from '@mui/material'
import { UserAvatar } from 'components'
import MultiSelectDropdownMenu from '.'
import { memberMenuItemStyles } from './menuItemStyles'

const OwnerMultiSelect = () => {
  const { membersWithAccess } = useContext(ActionPlanContext)
  const { ownerFilter, setOwnerFilter, clearOwnerFilter } = useContext(
    ActionPlanFilterContext
  )

  const ownerSelectProps = {
    label: 'Owner',
    currentFilter: ownerFilter,
    setCurrentFilter: setOwnerFilter,
    handleSelectClear: clearOwnerFilter,
    width: '33%',
    variant: MultiSelectMenuTypes.member
  }

  const selectMap = membersWithAccess
    ? membersWithAccess.map((member: any) => {
        return {
          id: member?.node?.userId,
          name: member?.node?.name ?? member?.node?.email
        }
      })
    : []

  return (
    <MultiSelectDropdownMenu {...ownerSelectProps}>
      {selectMap.map(({ id, name }) => {
        return (
          membersWithAccess && (
            <MenuItem value={name} sx={memberMenuItemStyles} key={id}>
              <Stack direction='row'>
                <UserAvatar username={name} size={32} />
                <Typography
                  variant='body14Light'
                  className='memberNameTypgraphy'
                >
                  {name}
                </Typography>
              </Stack>
            </MenuItem>
          )
        )
      })}
    </MultiSelectDropdownMenu>
  )
}
export default OwnerMultiSelect
