/* eslint-disable no-underscore-dangle */
import React from 'react'
import { Root, createRoot } from 'react-dom/client'
import './index.css'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { theme } from 'components'
import { MicroServiceProvider } from 'context/MicroService'
import { MicroServices } from 'model/microServices'
import { AlertProvider } from 'context/AlertContext'
import App from './App'

interface Props {
  container?: any
}

let root: Root

const render = (props: MicroServices & Props) => {
  const {
    container,
    displayNotification,
    maestroClient,

    currentAccount,
    hasAccessToViewRecommendations,
    hasAccessToViewDataViz,
    flags
  } = props
  root = createRoot(
    container
      ? container.querySelector('#namora-root')
      : document.getElementById('namora-root')
  )
  root.render(
    <React.StrictMode>
      <MicroServiceProvider
        displayNotification={displayNotification}
        maestroClient={maestroClient}
        currentAccount={currentAccount}
        hasAccessToViewRecommendations={hasAccessToViewRecommendations}
        hasAccessToViewDataViz={hasAccessToViewDataViz}
        flags={flags}
      >
        <AlertProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </StyledEngineProvider>
        </AlertProvider>
      </MicroServiceProvider>
    </React.StrictMode>
  )
}

export const bootstrap = async () => {}

export const mount = async (props: MicroServices) => {
  render(props)
}

export const unmount = async () => {
  root.unmount()
}
export const update = async (props: any) => {
  render(props)
}

if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({
    displayNotification: () => {},
    currentAccount: {
      id: '',
      employer: { name: '' },
      user: { id: '', name: '', primaryEmail: '' }
    },
    maestroClient: undefined,

    children: undefined,
    hasAccessToViewRecommendations: false,
    hasAccessToViewDataViz: false,
    flags: []
  })
}
