import { styled } from '@mui/material/styles'

import { Tab as MuiTab } from '@mui/material'

const Tab: any = styled(MuiTab)`
  cursor: pointer;
  font-weight: 400;
  background-color: #f7f7f7;
  width: fit-content;
  padding: 8px 16px;
  height: 29px;
  border: none;
  border-radius: 24px;
  display: flex;
  font-size: 11px;
  justify-content: center;
  min-height: 0;
  overflow: visible;
  &.Mui-selected {
    background-color: #373c3f;
    font-weight: 700;
    color: white;
  }
`
export default Tab
