import React, { useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import GoalPageVariant from 'pages/GoalPageVariant'
import PageNotFound from 'pages/PageNotFound'
import { MicroServiceContext } from 'context/MicroService'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from '@mui/x-license-pro'
import { errorPathname } from './constants'

const RouteProvider = () => {
  const { hasAccessToViewRecommendations } = useContext(MicroServiceContext)

  const { pathname } = useLocation()
  useEffect(() => {
    if (pathname === '/action-plan/recommendations') {
      document.title = 'Recommendations | Mathison'
    } else {
      document.title = 'Action Plan | Mathison'
    }
    return () => {
      document.title = 'Mathison'
    }
  }, [pathname])

  const dataGridProLicenseKey: string =
    process.env.REACT_APP_MUI_DATA_GRID_PRO || ''

  LicenseInfo.setLicenseKey(dataGridProLicenseKey)

  return (
    <Grid
      sx={{
        maxWidth: 1460,
        width: '90%',
        margin: '40px auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Grid sx={{ width: '100%' }}>
        <Routes>
          <Route
            path='/action-plan/recommendations/*'
            element={
              hasAccessToViewRecommendations ? (
                <GoalPageVariant />
              ) : (
                <Navigate to={errorPathname} />
              )
            }
          />
          <Route
            path='/action-plan/*'
            element={<GoalPageVariant isDefault />}
          />
          <Route path={errorPathname} element={<PageNotFound />} />
        </Routes>
      </Grid>
    </Grid>
  )
}
export default RouteProvider
