import React, { useContext, useReducer } from 'react'
import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'
import { ActionPlanContext } from 'context/ActionPlan'
import { MicroServiceContext } from 'context/MicroService'
import { GoalStatus, statusMap } from 'model/actionPlan'
import { StatusChip, Dialog } from 'components'
import { DialogState } from 'model'

const StatusSelect = () => {
  const { currentAccount } = useContext(MicroServiceContext)
  const { isReadOnly, selectedEmployerGoal, submitEmployerGoal } =
    useContext(ActionPlanContext)
  const DIALOG_INITIAL_VALUES: DialogState = {
    open: false,
    isSubmitting: false
  }
  const [dialogState, setDialogState] = useReducer(
    (state: DialogState, action: any) => {
      switch (action.type) {
        case 'close':
          return {
            ...state,
            isSubmitting: false,
            open: false
          }
        case 'loading':
          return {
            ...state,
            isSubmitting: true
          }
        default:
          return { ...state, [action.index]: action.value }
      }
    },
    DIALOG_INITIAL_VALUES
  )

  let selectedGoalId = ''
  let statusValue = ''

  if (selectedEmployerGoal) {
    ;({ id: selectedGoalId, status: statusValue } = selectedEmployerGoal)
  }

  const selectMap = statusMap
    ? statusMap.map((option: any) => {
        return { id: option.toLowerCase().replace(' ', '_'), status: option }
      })
    : []

  if (
    (isReadOnly && selectedEmployerGoal?.owner?.id !== currentAccount.id) ||
    selectedEmployerGoal?.isCompleted
  ) {
    return (
      <Grid item xs={5.5}>
        {statusValue && (
          <StatusChip
            label={
              GoalStatus[statusValue as unknown as keyof typeof GoalStatus]
            }
          />
        )}
      </Grid>
    )
  }
  return (
    <>
      <Dialog
        open={dialogState.open}
        onClose={() => setDialogState({ type: 'close' })}
        title='Do you want to mark this goal as complete?'
        content={selectedEmployerGoal?.title}
        banner={{
          type: 'warning',
          content: 'Goals that are marked complete can’t be reopened.'
        }}
        cancelProps={{
          disabled: dialogState.isSubmitting,
          onClick: () => {
            setDialogState({ type: 'close' })
          }
        }}
        confirmProps={{
          loading: dialogState.isSubmitting,
          onClick: () => {
            setDialogState({ type: 'loading' })
            submitEmployerGoal({ status: 'completed' as GoalStatus }, 'Status')
            setDialogState({ type: 'close' })
          }
        }}
      />
      <FormControl
        variant='filled'
        sx={{
          marginTop: '0',
          minWidth: '10rem',
          '& .MuiInputBase-root .MuiSelect-select': { paddingLeft: '16px' },
          '& .MuiInputBase-root .MuiSelect-filled': {
            height: '24px',
            paddingTop: '16px',
            paddingBottom: '16px'
          }
        }}
      >
        <Select
          value={selectedEmployerGoal?.status}
          renderValue={(status: any) => {
            if (status) {
              return (
                <Typography variant='body16'>
                  {GoalStatus[status as unknown as keyof typeof GoalStatus]}
                </Typography>
              )
            }
            return <Typography variant='body16'>Goal status</Typography>
          }}
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: 66,
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            PaperProps: { sx: { maxHeight: 270 } },
            sx: {
              '& .MuiMenu-list': {
                backgroundColor: '#FFF',
                paddingLeft: '0',
                '& .MuiMenuItem-root': {
                  borderRadius: 'unset'
                }
              }
            }
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={e => {
            const statusKey = e.target.value.toLowerCase().replace(' ', '_')
            if (statusKey === 'completed') {
              setDialogState({ index: 'open', value: statusKey })
            } else {
              submitEmployerGoal({ status: statusKey as GoalStatus }, 'Status')
            }
          }}
        >
          {selectMap.map(({ id, status }) => {
            return (
              <MenuItem value={status} key={id}>
                <StatusChip label={GoalStatus[id as keyof typeof GoalStatus]} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  )
}

export default StatusSelect
