import React, { useContext } from 'react'
import moment from 'moment'
import { Typography, Paper } from '@mui/material'
import { INITIAL_EMPLOYER_ASSESSMENT_HISTORY } from 'context/Dashboard'
import { GET_EMPLOYER_ASSESSMENT_HISTORY } from 'context/Dashboard/gql'
import { useQuery } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import MiniDashboardTable from './MiniDashboardTable'

const MiniDashboard = () => {
  const { maestroClient } = useContext(MicroServiceContext)
  const currentYear: number = moment().utc().year()
  const {
    data: employerAssessmentHistory = INITIAL_EMPLOYER_ASSESSMENT_HISTORY,
    loading
  } = useQuery(GET_EMPLOYER_ASSESSMENT_HISTORY, {
    client: maestroClient,
    variables: {
      year: currentYear.toString()
    }
  })

  if (loading) return null

  return (
    <Paper sx={{ padding: '32px', marginTop: '16px' }} variant='outlined'>
      <Typography variant='h7'>Current Equity Index Score</Typography>
      <MiniDashboardTable
        employerAssessmentHistory={
          employerAssessmentHistory.employerAssessmentHistory
        }
      />
    </Paper>
  )
}
export default MiniDashboard
