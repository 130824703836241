import { gql } from '@apollo/client'

// query
const GoalInput = gql`
  fragment goalInput on EmployerGoal {
    customTitle
    activeStatus
    startDate
    endDate
    owner {
      id
      name
      primaryEmail
    }
    members {
      id
      name
      primaryEmail
    }
    note
    isCompleted
    completedAt
    status
    effort
    impact
  }
`
export const GoalResponseFields = gql`
  fragment goalResponseFields on EmployerGoal {
    employerId
    activeStatus
    onTrack
    phase
    assessmentVersion
    assessmentQuestion
    kcUrls
    goalId
    rank
    customRank
    scoreDelta
    createdType
    employerGoalActions {
      id
      title
      description
      order
    }
  }
`

export const EMPLOYER_GOALS = gql`
  query EmployerGoal($employerId: String!) {
    employerGoals(employerId: $employerId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          category
          description
          ...goalInput
          ...goalResponseFields
        }
      }
    }
  }
  ${GoalInput}
  ${GoalResponseFields}
`
export const GET_EMPLOYER_STAFF_ACCOUNTS = gql`
  query EmployerStaffAccounts {
    employerStaffAccounts(
      first: 1000
      searchTerm: ""
      roles: ["FULL_EHI_ACCESS", "BASIC_EHI_ACCESS"]
      statuses: []
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        node {
          id
          email
          roles
          userId
          status
        }
      }
    }
  }
`

// mutation

export const UPDATE_EMPLOYER_GOAL = gql`
  mutation UpdateEmployerGoal($input: UpdateEmployerGoalInput!) {
    updateEmployerGoal(input: $input) {
      id
      phase
      ...goalInput
    }
  }
  ${GoalInput}
`

export const UPDATE_EMPLOYER_GOAL_RANK = gql`
  mutation UpdateEmployerGoalRank($input: UpdateEmployerGoalRankInput!) {
    updateEmployerGoalRank(input: $input) {
      employerGoals {
        id
        customRank
        rank
      }
    }
  }
`
