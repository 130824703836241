/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useState, useEffect } from 'react'
import { Color, GoalCard } from 'components'
import { ActionPlanContext } from 'context/ActionPlan'
import { MicroServiceContext } from 'context/MicroService'
import { EHIAccess } from 'model/access'
import { Grid, Typography, Box, Button, Stack } from '@mui/material'
import { goalNoteCache } from 'utils/actionPlan'
import he from 'he'
import ActiveNote from './ActiveNote'

const NotesSection = () => {
  const {
    currentAccount: { ehiAccess, id: currentAccountId }
  } = useContext(MicroServiceContext)
  const { selectedEmployerGoal } = useContext(ActionPlanContext)
  const getAccessToEditGoal = (goal: any) =>
    ehiAccess === EHIAccess.FULL_EHI_ACCESS ||
    goal.owner?.id === currentAccountId

  const [isEditing, setIsEditing] = useState(false)

  if (!selectedEmployerGoal) {
    return <GoalCard sx={{ padding: '32px', height: '100%' }} selected />
  }
  const { id, note: savedNote } = selectedEmployerGoal
  const noteIsReadOnly = !getAccessToEditGoal(selectedEmployerGoal)

  const decodedSavedNote = he.decode(savedNote || '')
  const unsavedNote = goalNoteCache.getItem(id)

  useEffect(() => {
    if (unsavedNote) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
  }, [unsavedNote, id])

  if (noteIsReadOnly) {
    return (
      <Grid item xs={12} marginTop={2}>
        {decodedSavedNote && (
          <Typography
            variant='body16Light'
            dangerouslySetInnerHTML={{
              __html: decodedSavedNote
            }}
          />
        )}
        {!decodedSavedNote && (
          <Typography variant='body16Light'>
            There are no notes for this goal.
          </Typography>
        )}
      </Grid>
    )
  }
  return (
    <Grid item xs={12} marginTop={2}>
      {!decodedSavedNote && !isEditing && !unsavedNote && (
        <Box
          border={`1px solid ${Color.grey300}`}
          borderRadius='8px'
          padding='12px'
          onClick={e => {
            e.stopPropagation()
            setIsEditing(true)
          }}
        >
          <Typography variant='body16Light'>Add a note...</Typography>
        </Box>
      )}
      {(decodedSavedNote || unsavedNote) && !isEditing && (
        <Stack spacing={3}>
          <Typography
            variant='body16Light'
            dangerouslySetInnerHTML={{
              __html: decodedSavedNote || ''
            }}
          />
          <Button
            sx={{ alignSelf: 'flex-start', padding: '8px 12px' }}
            variant='contained'
            color='secondary'
            onClick={e => {
              e.stopPropagation()
              setIsEditing(true)
            }}
          >
            Edit notes
          </Button>
        </Stack>
      )}
      {isEditing && (
        <ActiveNote
          id={id}
          onClose={() => {
            goalNoteCache.removeItem(id)
            setIsEditing(false)
          }}
          savedNote={decodedSavedNote}
          unsavedNote={unsavedNote}
        />
      )}
    </Grid>
  )
}

export default NotesSection
