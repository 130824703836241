import React, { ReactNode } from 'react'
import { Button } from '@mui/material'

interface Props {
  onClick: () => void
  disabled: boolean
  hidden: boolean
  children: ReactNode
  variant: any
}
const RecoHeaderButton = ({
  onClick,
  disabled,
  hidden,
  children,
  variant
}: Props) => (
  <Button
    sx={{ height: '44px', display: hidden ? 'none' : undefined }}
    variant={variant}
    size='small'
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </Button>
)
export default RecoHeaderButton
