import React from 'react'
import {
  Box,
  styled,
  Typography,
  FormControl as MuiFormControl,
  FormControlProps
} from '@mui/material'
import { Divider } from 'components'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

interface Props {
  sectionTitle?: string
  hasDivider?: boolean
}

const PanelSectionTitle = styled(({ sectionTitle, hasDivider }: Props) => (
  <Box>
    <Typography
      variant='subtitle2'
      sx={{ marginTop: 5, textAlign: 'left', textTransform: 'uppercase' }}
    >
      {sectionTitle}
    </Typography>
    {hasDivider && <Divider />}
  </Box>
))()

PanelSectionTitle.defaultProps = {
  hasDivider: true
}

type FormControlCustomProps = FormControlProps & { marginIndex?: number }

const FormControl = styled(MuiFormControl)(
  ({ marginIndex = 2 }: FormControlCustomProps) => ({
    marginTop: marginIndex * 8
  })
)

type LoadingButtonCustomProps = LoadingButtonProps & { loadingWidth?: number }

const LoadButton = styled(LoadingButton)<LoadingButtonCustomProps>(
  ({ loadingWidth = 72 }) => ({
    '&.MuiLoadingButton-loading': {
      width: loadingWidth,
      justifyContent: 'start'
    }
  })
)

export { PanelSectionTitle, FormControl, LoadButton }
