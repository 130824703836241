import React, { useContext } from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { ActionPlanContext } from 'context/ActionPlan'
import { MicroServiceContext } from 'context/MicroService'
import { GoalLevel, levelMap } from 'model/actionPlan'
import { ArrowDownward, ArrowForward, ArrowUpward } from '@mui/icons-material'

const ImpactSelect = () => {
  const { currentAccount } = useContext(MicroServiceContext)
  const { isReadOnly, selectedEmployerGoal, submitEmployerGoal } =
    useContext(ActionPlanContext)

  let selectedGoalId = ''
  let impactValue = ''
  let effortValue = ''

  if (selectedEmployerGoal) {
    ;({
      id: selectedGoalId,
      impact: impactValue,
      effort: effortValue
    } = selectedEmployerGoal)
  }

  const selectMap = levelMap
    ? levelMap.map((option: any) => {
        return { id: option.toLowerCase(), impact: option }
      })
    : []

  if (
    (isReadOnly && selectedEmployerGoal?.owner?.id !== currentAccount.id) ||
    selectedEmployerGoal?.isCompleted
  ) {
    return (
      <Grid item xs={5.5}>
        <Typography variant='body16Light' component='p' marginBottom='8px'>
          Prioritize your goal
        </Typography>
        {impactValue === 'high' && (
          <Stack direction='row'>
            <Typography variant='body16' paddingRight={1}>
              High Impact
            </Typography>
            <ArrowUpward sx={{ color: '#32B996' }} />
          </Stack>
        )}
        {impactValue === 'low' && (
          <Stack direction='row'>
            <Typography variant='body16' paddingRight={1}>
              Low Impact
            </Typography>
            <ArrowDownward sx={{ color: '#FE6868' }} />
          </Stack>
        )}
        {impactValue === 'medium' && (
          <Stack direction='row'>
            <Typography variant='body16' paddingRight={1}>
              Med Impact
            </Typography>
            <ArrowForward sx={{ color: '#FCA625' }} />
          </Stack>
        )}
        {impactValue === null && effortValue !== null && (
          <Stack direction='row'>
            <Typography variant='body16' paddingRight={1}>
              Impact not set
            </Typography>
          </Stack>
        )}
        {impactValue === null && effortValue === null && (
          <Stack direction='row'>
            <Typography variant='body16' whiteSpace='nowrap' paddingRight={1}>
              Impact and Effort not set
            </Typography>
          </Stack>
        )}
      </Grid>
    )
  }
  return (
    <Grid item xs={5.5}>
      <Typography variant='body16' component='p' marginBottom='8px'>
        Prioritize your goal
      </Typography>
      <FormControl
        fullWidth
        variant='filled'
        sx={{
          marginTop: '0',
          '& .MuiInputLabel-root': {
            transform: 'translate(16px, 20px) scale(1)'
          },
          '& .MuiInputBase-root': {
            height: '64px'
          },
          '& .MuiInputBase-root .MuiSelect-select': {
            paddingTop: '30px',
            paddingBottom: '10px',
            paddingLeft: '16px'
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(16px, 10px) scale(1)'
          },
          '& .MuiInputLabel-shrink .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px'
          }
        }}
      >
        <InputLabel>
          <Typography variant='body16'>Impact</Typography>
        </InputLabel>
        <Select
          value={selectedEmployerGoal?.impact}
          renderValue={(impact: any) => {
            if (impact === 'high') {
              return (
                <Stack display='flex' direction='row' paddingTop='4px'>
                  <Typography variant='body16' paddingRight={1}>
                    High
                  </Typography>
                  <ArrowUpward sx={{ color: '#32B996' }} />
                </Stack>
              )
            }
            if (impact === 'low') {
              return (
                <Stack display='flex' direction='row' paddingTop='4px'>
                  <Typography variant='body16' paddingRight={1}>
                    Low
                  </Typography>
                  <ArrowDownward sx={{ color: '#FE6868' }} />
                </Stack>
              )
            }
            if (impact === 'medium') {
              return (
                <Stack display='flex' direction='row' paddingTop='4px'>
                  <Typography variant='body16' paddingRight={1}>
                    Med
                  </Typography>
                  <ArrowForward sx={{ color: '#FCA625' }} />
                </Stack>
              )
            }
            return ''
          }}
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: 66,
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            PaperProps: { sx: { maxHeight: 270 } },
            sx: {
              '& .MuiMenu-list': {
                backgroundColor: '#FFF',
                paddingLeft: '0',
                paddingRight: '0',
                cursor: 'pointer',
                '& .MuiMenuItem-root': {
                  borderRadius: 'unset'
                }
              }
            }
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={e => {
            const impactKey = e.target.value.toLowerCase()
            submitEmployerGoal({ impact: impactKey as GoalLevel }, 'Impact')
          }}
        >
          {selectMap.map(({ id, impact }) => {
            return (
              <MenuItem value={impact} key={id}>
                {impact === 'High' && (
                  <Stack direction='row'>
                    <Typography variant='body16' paddingRight={1}>
                      {impact}
                    </Typography>
                    <ArrowUpward sx={{ color: '#32B996' }} />
                  </Stack>
                )}
                {impact === 'Low' && (
                  <Stack direction='row'>
                    <Typography variant='body16' paddingRight={1}>
                      {impact}
                    </Typography>
                    <ArrowDownward sx={{ color: '#FE6868' }} />
                  </Stack>
                )}
                {impact === 'Medium' && (
                  <Stack direction='row'>
                    <Typography variant='body16' paddingRight={1}>
                      Med
                    </Typography>
                    <ArrowForward sx={{ color: '#FCA625' }} />
                  </Stack>
                )}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Grid>
  )
}

export default ImpactSelect
