const defaultButtons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'ul',
  'ol',
  '|',
  'link',
  'eraser'
]
export const config = {
  readonly: false,
  showTooltip: true,
  activeButtonsInReadOnly: [], // force block source in readonly mode
  tabIndex: -1,
  autofocus: true,
  sourceEditor: 'area',
  beautifyHTML: true,
  cursorAfterAutofocus: 'end',
  askBeforePasteFromWord: false,
  link: {
    formClassName: 'jodit-custom-popup',
    noFollowCheckbox: false,
    openInNewTabCheckbox: false,
    modeClassName: undefined
  },
  buttons: defaultButtons,
  buttonsMD: defaultButtons,
  buttonsSM: defaultButtons,
  buttonsXS: defaultButtons,
  maxHeight: 300,
  askBeforePasteHTML: false,
  hidePoweredByJodit: true,
  statusbar: false,
  // override default css setting
  styleValues: {
    borderRadiusDefault: '8px',
    colorBorder: 'black',
    fontDefault: 'IBM Plex Sans',
    fontSizeDefault: '16px'
  },
  editorCssClass: 'custom-note-editor'
}
