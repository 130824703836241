import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { MicroServiceContext } from 'context/MicroService'
import {
  HERO,
  HERO_W_NO_EI_ACCESS,
  HERO_W_ZERO_GOALS,
  HERO_W_BASIC_EI_ACCESS
} from './constants'
import {
  ImageInfo,
  ImageBlock,
  InfoBlock,
  InfoButton
} from './styledComponents'

const ImageInfoBlock = () => {
  const { hasAccessToViewRecommendations } = useContext(MicroServiceContext)
  const { pageType = '' }: any = useLocation().state || {}
  let heroData = { ...HERO, ...HERO_W_NO_EI_ACCESS }
  if (hasAccessToViewRecommendations) {
    if (pageType === 'view-goals') {
      heroData = { ...HERO, ...HERO_W_BASIC_EI_ACCESS }
    } else if (pageType === 'zero-goals') {
      heroData = HERO_W_ZERO_GOALS
    }
  }

  return (
    <ImageInfo>
      <ImageBlock imageUrl={heroData.image} />
      <InfoBlock>
        {heroData?.title?.length > 0 && (
          <Typography
            variant='formLabel'
            marginBottom={3}
            sx={{
              textTransform: 'uppercase'
            }}
          >
            {heroData.title}
          </Typography>
        )}
        <Typography variant='h1' marginBottom={3}>
          {heroData.message}
        </Typography>
        <Typography variant='body16Light' marginBottom={5}>
          {heroData.desc}
        </Typography>
        <Grid container>
          <Grid item xs={7}>
            <InfoButton href={heroData.btnUrl} variant='contained'>
              {heroData.btnTitle}
            </InfoButton>
          </Grid>
        </Grid>
      </InfoBlock>
    </ImageInfo>
  )
}

export default ImageInfoBlock
