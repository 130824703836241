import React, {
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { Drawer, Stack } from '@mui/material'
import { Color } from 'components'

interface SidePanelProps {
  // eslint-disable-next-line react/require-default-props
  beforePanelOnClose?: any | null
  // eslint-disable-next-line react/require-default-props
  afterPanelOnClose?: any | null
}

const SidePanel = forwardRef(
  (
    {
      children,
      beforePanelOnClose = null,
      afterPanelOnClose = null,
      ...rest
    }: PropsWithChildren & SidePanelProps,
    ref
  ) => {
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const drawerRef = useRef<HTMLDivElement>(null)
    const defaultDuration = 300

    const togglePanel = (isOpen: boolean) => {
      setIsPanelOpen(isOpen)
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          openSidePanel: () => {
            if (!isPanelOpen) {
              togglePanel(true)
            }
          },
          closeSidePanel: (onExitedCallback: any) => {
            if (isPanelOpen) {
              togglePanel(false)
              if (onExitedCallback) {
                setTimeout(() => onExitedCallback(), defaultDuration)
              }
            }
          }
        }
      },
      [isPanelOpen]
    )

    const handleClose = async () => {
      if (beforePanelOnClose) await beforePanelOnClose()

      togglePanel(false)

      if (afterPanelOnClose) await afterPanelOnClose()
    }

    return (
      <Drawer
        {...rest}
        transitionDuration={{ enter: defaultDuration, exit: defaultDuration }}
        anchor='right'
        open={isPanelOpen}
        onClose={handleClose}
        ref={drawerRef}
        sx={{
          zIndex: 1300,
          '& .MuiBackdrop-root': {
            backgroundColor: Color.grey600,
            opacity: '0.8 !important'
          }
        }}
      >
        <Stack
          sx={{ padding: '40px', width: 844, zIndex: 1310 }}
          role='presentation'
        >
          {children}
        </Stack>
      </Drawer>
    )
  }
)

export default SidePanel
