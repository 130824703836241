import { GridFilterModel, GridLinkOperator } from '@mui/x-data-grid-pro'
import React, { createContext, useEffect, useMemo, useState } from 'react'

interface ActionPlanFilter {
  categoryFilter: string[]
  setCategoryFilter: (str: string[]) => void
  clearCategoryFilter: () => void
  statusFilter: string[]
  setStatusFilter: (str: string[]) => void
  clearStatusFilter: () => void
  ownerFilter: string[]
  setOwnerFilter: (str: string[]) => void
  clearOwnerFilter: () => void
  filterModel: GridFilterModel
  clearAllFilters: () => void
  isSortable: boolean
  setIsSortable: (bool: boolean) => void
  gridKey: number
  setGridKey: (key: any) => void
}

export const ActionPlanFilterContext = createContext<ActionPlanFilter>({
  categoryFilter: [],
  setCategoryFilter: () => {},
  clearCategoryFilter: () => {},
  statusFilter: [],
  setStatusFilter: () => {},
  clearStatusFilter: () => {},
  ownerFilter: [],
  setOwnerFilter: () => {},
  clearOwnerFilter: () => {},
  filterModel: { items: [] },
  clearAllFilters: () => {},
  isSortable: true,
  setIsSortable: () => {},
  gridKey: 0,
  setGridKey: () => {}
})

const initFilterModel: GridFilterModel = {
  items: [
    {
      id: 0,
      columnField: 'cate',
      operatorValue: 'isAnyOf',
      value: []
    },
    {
      id: 1,
      columnField: 'status',
      operatorValue: 'isAnyOf',
      value: []
    },
    {
      id: 2,
      columnField: 'owner',
      operatorValue: 'isAnyOf',
      value: []
    }
  ],
  linkOperator: GridLinkOperator.And
}

export const ActionPlanFilterProvider = ({ children }: any): JSX.Element => {
  const isLoading = false
  const [categoryFilter, setCategoryFilter] = useState<string[]>([])
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [ownerFilter, setOwnerFilter] = useState<string[]>([])
  const [filterModel, setFilterModel] =
    useState<GridFilterModel>(initFilterModel)
  const [isSortable, setIsSortable] = useState<boolean>(true)
  const [gridKey, setGridKey] = useState<number>(0)

  useEffect(() => {
    setFilterModel({
      items: [
        {
          id: 0,
          columnField: 'cate',
          operatorValue: 'isAnyOf',
          value: categoryFilter
        },
        {
          id: 1,
          columnField: 'status',
          operatorValue: 'isAnyOf',
          value: statusFilter
        },
        {
          id: 2,
          columnField: 'owner',
          operatorValue: 'isAnyOf',
          value: ownerFilter
        }
      ],
      linkOperator: GridLinkOperator.And
    })
  }, [categoryFilter, statusFilter, ownerFilter])

  const clearCategoryFilter = () => {
    setCategoryFilter([])
  }
  const clearStatusFilter = () => {
    setStatusFilter([])
  }
  const clearOwnerFilter = () => {
    setOwnerFilter([])
  }
  const clearAllFilters = () => {
    setCategoryFilter([])
    setStatusFilter([])
    setOwnerFilter([])
  }

  const actionPlanFilterValues = useMemo(
    () => ({
      isLoading,
      categoryFilter,
      setCategoryFilter,
      clearCategoryFilter,
      statusFilter,
      setStatusFilter,
      clearStatusFilter,
      ownerFilter,
      setOwnerFilter,
      clearOwnerFilter,
      filterModel,
      clearAllFilters,
      isSortable,
      setIsSortable,
      gridKey,
      setGridKey
    }),
    [
      isLoading,
      categoryFilter,
      statusFilter,
      ownerFilter,
      filterModel,
      isSortable,
      gridKey
    ]
  )

  return (
    <ActionPlanFilterContext.Provider value={actionPlanFilterValues}>
      {children}
    </ActionPlanFilterContext.Provider>
  )
}
