import React, { useContext } from 'react'
import { Grid, Typography, MenuItem, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { ActionPlanContext } from 'context/ActionPlan'
import { UserAvatar } from 'components'
import { Member } from 'model/actionPlan'

interface Props {
  isShowingMore: boolean
}

const SelectedMembers = ({ isShowingMore }: Props) => {
  const { selectedEmployerGoal, submitEmployerGoal } =
    useContext(ActionPlanContext)
  let teamMembersOnGoal: Member[] = []

  if (selectedEmployerGoal?.id) {
    ;({ members: teamMembersOnGoal } = selectedEmployerGoal)
  }

  return (
    <>
      {(isShowingMore ? teamMembersOnGoal : teamMembersOnGoal.slice(0, 5)).map(
        (member: Member, index: number) => {
          const { id: teamMemberId, name, primaryEmail } = member
          return (
            <MenuItem
              key={teamMemberId}
              disableRipple
              sx={{ marginTop: '8px', padding: '8px 12px', height: 56 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={1.6} maxWidth='46px'>
                  <UserAvatar
                    size={32}
                    username={name ?? primaryEmail ?? 'Unknown'}
                  />
                </Grid>
                <Grid item xs={8.4}>
                  <Typography
                    variant='body2'
                    component='div'
                    fontWeight={600}
                    textOverflow='ellipsis'
                    overflow='hidden'
                  >
                    {name}
                  </Typography>
                  <Typography
                    variant='body2'
                    textOverflow='ellipsis'
                    overflow='hidden'
                  >
                    {primaryEmail}
                  </Typography>
                </Grid>
                <Grid item width='46px' xs={1.6}>
                  <IconButton
                    onClick={() => {
                      let thisTeamMembers = [...teamMembersOnGoal]
                      thisTeamMembers.splice(index, 1)
                      thisTeamMembers = thisTeamMembers.map((m: any) => {
                        const payload = {
                          id: m.id,
                          name: m.name,
                          primaryEmail: m.primaryEmail
                        }
                        return payload
                      })
                      submitEmployerGoal(
                        { members: thisTeamMembers },
                        'Team Members'
                      )
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </MenuItem>
          )
        }
      )}
    </>
  )
}
export default SelectedMembers
