import React, { useContext } from 'react'
import { ActionPlanContext } from 'context/ActionPlan'
import { EHIAccess } from 'model/access'
import { Navigate } from 'react-router-dom'
import { errorPathname } from 'routes/constants'
import { MicroServiceContext } from 'context/MicroService'
import PanelHeader from './PanelHeader'
import PanelDetails from './PanelDetails'
import PanelFooter from './PanelFooter'

const SidePanelContent = () => {
  const {
    currentAccount: { ehiAccess, id: currentAccountId }
  } = useContext(MicroServiceContext)
  const { setIsReadOnly, selectedEmployerGoal, hasAccessToSearchParamGoal } =
    useContext(ActionPlanContext)

  if (!selectedEmployerGoal) return null

  const getAccessToEditGoal = (goal: any) =>
    ehiAccess === EHIAccess.FULL_EHI_ACCESS ||
    goal.owner?.id === currentAccountId
  setIsReadOnly(!getAccessToEditGoal(selectedEmployerGoal))

  if (hasAccessToSearchParamGoal) {
    return (
      <>
        <PanelHeader />
        <PanelDetails />
        <PanelFooter />
      </>
    )
  }
  return <Navigate to={errorPathname} state={{ pageType: 'view-goals' }} />
}

export default SidePanelContent
