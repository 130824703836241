import React, { useContext, useLayoutEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import Paper, { PaperProps } from '@mui/material/Paper'
import { SideNavContext } from 'context/SideNavContext'
import ImageInfoBlock from './ImageInfoBlock'

const Container = styled(Paper)<PaperProps>(() => ({
  borderRadius: '8px',
  padding: 0,
  width: '100%'
}))

const PageNotFound = () => {
  const { setSideNavIfNecessary } = useContext(SideNavContext)

  useLayoutEffect(() => {
    setSideNavIfNecessary(false)
    return () => {
      setSideNavIfNecessary(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid my={15} container>
      <Container>
        <ImageInfoBlock />
      </Container>
    </Grid>
  )
}

export default PageNotFound
