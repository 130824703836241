import React from 'react'
import { Color } from 'components'
import {
  OutlinedInputProps,
  styled,
  TextField,
  TextFieldProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material'

export const PanelTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement='top-end' />
))(() => ({
  inset: 'auto 0px -12px auto !important',
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Color.main,
    color: Color.white,
    fontFamily: 'IBM Plex Sans',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    padding: '8px 10px',
    maxWidth: '600px'
  }
}))

interface Props {
  type?: 'title' | 'description'
  value: string
  countLimit: number
}

export const TextFieldInput = styled((props: TextFieldProps & Props) => (
  <TextField
    InputProps={
      {
        disableUnderline: true,
        endAdornment: (
          <Typography
            variant='body2'
            sx={{ position: 'absolute', bottom: '12px', right: '8px' }}
          >
            {props.value.length} / {props.countLimit}
          </Typography>
        )
      } as Partial<OutlinedInputProps>
    }
    {...props}
  />
))(({ theme, type }) => {
  const baseStyles = {
    '& .MuiFilledInput-root': {
      overflow: 'hidden',
      borderRadius: 8,
      backgroundColor: Color.white,
      border: '1px solid',
      borderColor: Color.grey300,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow'
      ]),
      padding: '32px 16px',
      '&:hover': {
        backgroundColor: Color.grey50
      },
      '&.Mui-focused': {
        backgroundColor: Color.grey50,
        borderColor: Color.main
      },
      '& .MuiInputBase-input':
        type === 'title'
          ? {
              fontFamily: 'IBM Plex Sans',
              fontWeight: 700,
              fontSize: 28,
              lineHeight: '32px',
              letterSpacing: '-0.02rem'
            }
          : {}
    }
  }
  const titleStyles = {
    '& .MuiInputBase-input': {
      fontFamily: 'IBM Plex Sans',
      fontWeight: 700,
      fontSize: 28,
      lineHeight: '32px',
      letterSpacing: '-0.02rem'
    }
  }
  if (type === 'title') {
    return { ...baseStyles, ...titleStyles }
  }
  return baseStyles
})
