import React from 'react'
import { CircularProgress, Grid } from '@mui/material'

const LoadingCircular = () => (
  <Grid item flexGrow={1} container alignItems='center' justifyContent='center'>
    <CircularProgress size={40} thickness={4} />
  </Grid>
)

export default LoadingCircular
