import React, { useContext, useMemo, useState } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import EastIcon from '@mui/icons-material/East'
import { ActionPlanContext } from 'context/ActionPlan'
import { DatePicker } from 'components'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import { MicroServiceContext } from 'context/MicroService'
import { AlertContext } from 'context/AlertContext'

const TimeframeSelect = () => {
  const { currentAccount } = useContext(MicroServiceContext)
  const { displayAlertMessage } = useContext(AlertContext)
  const { isReadOnly, selectedEmployerGoal, submitEmployerGoal } =
    useContext(ActionPlanContext)
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)

  let selectedGoalId = ''
  let rawStartDate: any = null
  let rawEndDate: any = null

  if (selectedEmployerGoal) {
    ;({
      id: selectedGoalId,
      startDate: rawStartDate,
      endDate: rawEndDate
    } = selectedEmployerGoal)
  }

  useMemo(() => {
    if (rawStartDate) {
      setStartDate(moment(rawStartDate))
    } else {
      setStartDate(null)
    }
    if (rawEndDate) {
      setEndDate(moment(rawEndDate))
    } else {
      setEndDate(null)
    }
    // eslint-disable-next-line
  }, [selectedGoalId])

  moment.updateLocale(moment.locale(), { invalidDate: 'Date not set' })

  const submitStartDate = (dateInput: Moment) => {
    if (dateInput.isAfter(rawEndDate)) {
      displayAlertMessage('End date can not be earlier than start date')
      return
    }
    submitEmployerGoal(
      {
        startDate: dateInput.toISOString()
      },
      'Start date'
    )
  }
  const submitEndDate = (dateInput: Moment) => {
    if (moment(rawStartDate).isAfter(dateInput)) {
      displayAlertMessage('End date can not be earlier than start date')
      return
    }
    submitEmployerGoal(
      {
        endDate: dateInput.toISOString()
      },
      'End date'
    )
  }

  const detailsTitles = {
    date: 'What’s your timeline?'
  }

  if (
    (isReadOnly && selectedEmployerGoal?.owner?.id !== currentAccount.id) ||
    selectedEmployerGoal?.isCompleted
  ) {
    return (
      <Grid item xs={6} marginTop={3}>
        <Typography variant='body16Light' component='p' marginBottom='8px'>
          {detailsTitles.date}
        </Typography>
        {(rawStartDate || rawEndDate) && (
          <Stack direction='row' spacing={2} alignItems='baseline'>
            <Typography variant='body16'>
              {rawStartDate
                ? moment(rawStartDate).format('MMM YYYY')
                : 'No start date'}
            </Typography>
            <Box>
              <EastIcon sx={{ verticalAlign: '-6px' }} />
            </Box>
            <Typography variant='body16'>
              {rawEndDate
                ? moment(rawEndDate).format('MMM YYYY')
                : 'No end date'}
            </Typography>
          </Stack>
        )}
        {rawStartDate === null && rawEndDate === null && (
          <Stack direction='row' spacing={2} alignItems='baseline'>
            <Typography variant='body16'>No dates set </Typography>
          </Stack>
        )}
      </Grid>
    )
  }
  return (
    <Grid item xs={6} marginTop={3}>
      <Typography variant='body16'>{detailsTitles.date}</Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack
          direction='row'
          spacing={2}
          alignItems='baseline'
          sx={{
            '& .MuiTextField-root': {
              marginTop: '8px'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#E5E5E5'
            }
          }}
        >
          <DatePicker
            inputFormat='MMM YYYY'
            value={startDate}
            defaultValue={selectedEmployerGoal?.startDate}
            onAccept={(date: Moment) => submitStartDate(date)}
            onChange={(newValue: Moment | null) => {
              setStartDate(newValue)
            }}
          />
          <Box>
            <EastIcon sx={{ verticalAlign: '-6px' }} />
          </Box>
          <DatePicker
            inputFormat='MMM YYYY'
            isEndMonth
            value={endDate}
            defaultValue={selectedEmployerGoal?.endDate}
            onAccept={(date: Moment) => submitEndDate(date)}
            onChange={(newValue: Moment | null) => {
              setEndDate(newValue)
            }}
          />
        </Stack>
      </LocalizationProvider>
    </Grid>
  )
}

export default TimeframeSelect
