/* eslint-disable no-plusplus */
import { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { EMPLOYER_GOALS } from 'context/ActionPlan/gql'
import { MicroServiceContext } from 'context/MicroService'

const useEmployerGoals = () => {
  const [employerGoals, setEmployerGoals] = useState<any>()
  const { currentAccount } = useContext(MicroServiceContext)
  const { data: rawGoals } = useQuery(EMPLOYER_GOALS, {
    variables: { employerId: currentAccount.employer.id }
  })

  useEffect(() => {
    if (rawGoals) {
      const { employerGoals: rawEmployerGoals } = rawGoals
      const { edges } = rawEmployerGoals

      let displayRank = 1
      // rely on bullseye's ranking sort logic and assign display rank
      const newEdges = edges?.map((edge: { node: any }) => ({
        ...edge,
        ...{
          node: {
            ...edge.node,
            displayRank: displayRank++
          }
        }
      }))

      setEmployerGoals({
        ...rawEmployerGoals,
        edges: newEdges
      })
    }
  }, [rawGoals])

  return { employerGoals }
}

export default useEmployerGoals
