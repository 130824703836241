import React from 'react'
import { Box, Grid } from '@mui/material'
import { DescriptionBanner, Color } from 'components'
import { RecoNode } from 'model/recommendations'
import Card from './Card'
import ExpandedGoal from '../ExpandedReco'

interface Props {
  recos: RecoNode[]
}

const CardView = ({ recos }: Props) => {
  // eslint-disable-next-line no-underscore-dangle
  const boxHeight = (window as any).__POWERED_BY_QIANKUN__ ? 258 : 154 // Kangaroo headers height + EHI GM category tabs height + Padding + (footer height)
  const boxHtCalc = `calc(100vh - ${boxHeight}px)`
  if (recos.length === 0)
    return (
      <Grid
        item
        padding='0 32px 32px'
        flexGrow={1}
        bgcolor='white'
        border={`1px solid ${Color.grey300}`}
        borderTop='none'
        borderRadius='0px 0px 8px 8px'
      >
        <DescriptionBanner dense>There are no matching goals</DescriptionBanner>
      </Grid>
    )
  return (
    <Grid
      item
      container
      flexGrow={1}
      id='scroll-card-view-panel'
      bgcolor='white'
      border={`1px solid ${Color.grey300}`}
      borderTop='none'
      borderRadius='0px 0px 8px 8px'
      sx={{
        overflowY: 'hidden',
        maxHeight: boxHtCalc
      }}
    >
      <Grid item xs={3.8} paddingLeft={3} position='relative'>
        <Box
          id='scroll-card-view-list'
          sx={{
            overflowY: 'auto',
            maxHeight: boxHtCalc,
            position: 'absolute',
            top: 0,
            bottom: 0,
            paddingBottom: '15px',
            paddingRight: '16px',
            width: '100%'
          }}
        >
          {recos.map(({ node }: RecoNode) => (
            <Card reco={node} key={node?.id} />
          ))}
        </Box>
      </Grid>
      <Grid item xs={8.2} paddingLeft='24px'>
        <Box
          sx={{
            overflowY: 'auto',
            maxHeight: boxHtCalc,
            paddingBottom: 4,
            paddingRight: 3
          }}
        >
          <ExpandedGoal />
        </Box>
      </Grid>
    </Grid>
  )
}

export default CardView
