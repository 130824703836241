import { Category, EmployerAssessmentHistory } from 'model/assessment'

export const getPreviousAndCurrentScores = (
  employerAssessmentHistory: EmployerAssessmentHistory
) => {
  if (!employerAssessmentHistory) return []
  const { firstQuarter, secondQuarter, thirdQuarter, fourthQuarter } =
    employerAssessmentHistory
  const previousAndCurrentScores = []

  if (firstQuarter !== null)
    previousAndCurrentScores.push({ quarter: 1, ...firstQuarter })
  if (secondQuarter !== null)
    previousAndCurrentScores.push({ quarter: 2, ...secondQuarter })
  if (thirdQuarter !== null)
    previousAndCurrentScores.push({ quarter: 3, ...thirdQuarter })
  if (fourthQuarter !== null)
    previousAndCurrentScores.push({ quarter: 4, ...fourthQuarter })

  return previousAndCurrentScores.length > 1
    ? previousAndCurrentScores.slice(-2)
    : [undefined, ...previousAndCurrentScores]
}

// set category order by slug
const orderedCategories = [
  { slug: 'defining-tracking' },
  { slug: 'attracting-sourcing' },
  { slug: 'interviewing-engaging' },
  { slug: 'onboarding-advancing' }
]

// Used in Progress By Category
export const orderProgressByCategory = (
  categories?: Category[]
): Category[] | undefined => {
  const result: Category[] = []
  if (categories && categories.length > 0) {
    orderedCategories.forEach(orderedCategory => {
      const filteredCategory = categories.filter(
        category => orderedCategory.slug === category.slug
      )
      result.push(filteredCategory[0])
    })
  }
  return result
}

// Used in Category Progress By Quarter
export const groupScoresByCategory = (
  employerAssessmentHistory: EmployerAssessmentHistory
): Category[][] => {
  const categoryTemplate: Category[] = [
    {
      __typename: 'EmployerAssessmentSection',
      id: '',
      name: 'Defining and Tracking',
      slug: 'defining-tracking',
      acquiredScore: 0
    },
    {
      __typename: 'EmployerAssessmentSection',
      id: '',
      name: 'Attracting and Sourcing',
      slug: 'attracting-sourcing',
      acquiredScore: 0
    },
    {
      __typename: 'EmployerAssessmentSection',
      id: '',
      name: 'Interviewing and Engaging',
      slug: 'interviewing-engaging',
      acquiredScore: 0
    },
    {
      __typename: 'EmployerAssessmentSection',
      id: '',
      name: 'Onboarding and Advancing',
      slug: 'onboarding-advancing',
      acquiredScore: 0
    }
  ]

  const employerAssessmentCategoriesQ1: Category[] =
    employerAssessmentHistory && employerAssessmentHistory.firstQuarter !== null
      ? employerAssessmentHistory.firstQuarter.employerAssessmentCategories
      : categoryTemplate
  const employerAssessmentCategoriesQ2: Category[] =
    employerAssessmentHistory &&
    employerAssessmentHistory.secondQuarter !== null
      ? employerAssessmentHistory.secondQuarter?.employerAssessmentCategories
      : categoryTemplate
  const employerAssessmentCategoriesQ3: Category[] =
    employerAssessmentHistory && employerAssessmentHistory.thirdQuarter !== null
      ? employerAssessmentHistory.thirdQuarter?.employerAssessmentCategories
      : categoryTemplate
  const employerAssessmentCategoriesQ4: Category[] =
    employerAssessmentHistory &&
    employerAssessmentHistory.fourthQuarter !== null
      ? employerAssessmentHistory.fourthQuarter?.employerAssessmentCategories
      : categoryTemplate

  // Instantiate data template
  const assessmentCategories: Category[][] = [[], [], [], []]

  // Map through the category order and filter by slug to construct the assessmentCategories array
  orderedCategories.forEach((category: any, index: number) => {
    const sortedCategoryQ1 = employerAssessmentCategoriesQ1.filter(
      assessmentCategory => category.slug === assessmentCategory.slug
    )
    const sortedCategoryQ2 = employerAssessmentCategoriesQ2.filter(
      assessmentCategory => category.slug === assessmentCategory.slug
    )
    const sortedCategoryQ3 = employerAssessmentCategoriesQ3.filter(
      assessmentCategory => category.slug === assessmentCategory.slug
    )
    const sortedCategoryQ4 = employerAssessmentCategoriesQ4.filter(
      assessmentCategory => category.slug === assessmentCategory.slug
    )
    assessmentCategories[index].push(
      sortedCategoryQ1[0],
      sortedCategoryQ2[0],
      sortedCategoryQ3[0],
      sortedCategoryQ4[0]
    )
  })

  return assessmentCategories
}
