import React from 'react'
import {
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid-pro'
import { Box, Stack, Typography } from '@mui/material'
import {
  ArrowUpward,
  ArrowDownward,
  ArrowForward,
  East
} from '@mui/icons-material'
import moment from 'moment'
import {
  categoryMap,
  statusMap,
  Member,
  GoalLevel,
  GoalStatus,
  GoalCategory
} from 'model/actionPlan'
import { Color, GoalTag, StatusChip, UserAvatar } from 'components'
import { parseGoalCategory } from 'utils/actionPlan'
import { CATEGORY_COLOR_5 } from 'constants/'
import { DataGridTooltip } from './styledComponents'

const sortCol = (sortMap: string[]) => (a: string, b: string) => {
  if (a === null) return 1
  if (b === null) return -1
  return sortMap.indexOf(a) - sortMap.indexOf(b)
}

const impactEffortMap = ['low', 'medium', 'high']

export const getActionPlan = (isSortable: boolean) => {
  return {
    columns: [
      {
        field: 'id',
        hide: true
      },
      {
        field: 'rank',
        headerName: 'Rank',
        sortable: isSortable,
        flex: 1,
        minWidth: 75,
        valueGetter: (params: GridValueGetterParams<number>) =>
          params?.row?.node?.displayRank,
        type: 'number',
        headerClassName: 'app-plan-header-rank',
        cellClassName: 'action-plan-app--cell-rank',
        renderCell: (params: GridRenderCellParams<number>) => {
          return (
            <Typography
              variant='subtitle2'
              sx={{
                height: 32,
                width: 32,
                backgroundColor: Color.grey100,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {params?.row?.node?.displayRank}
            </Typography>
          )
        }
      },
      {
        field: 'title',
        headerName: 'Goal',
        sortable: isSortable,
        flex: 3,
        minWidth: 200,
        cellClassName: 'action-plan-app--cell-goal',
        valueGetter: (params: GridValueGetterParams<string>) => {
          if (params?.row?.node?.customTitle) {
            return params?.row?.node?.customTitle
          }
          return params?.row?.node?.title
        },
        renderCell: (params: GridRenderCellParams<string>) => {
          let goalTitle = ''
          if (params?.row?.node?.customTitle) {
            goalTitle = params?.row?.node?.customTitle
          } else {
            goalTitle = params?.row?.node?.title
          }
          return (
            <DataGridTooltip title={goalTitle}>
              <Typography
                variant='body14Light'
                sx={{
                  display: '-webkit-box',
                  wordBreak: 'break-word',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: '2',
                  whiteSpace: 'normal'
                }}
                textOverflow='ellipsis'
                overflow='hidden'
              >
                {goalTitle}
              </Typography>
            </DataGridTooltip>
          )
        }
      },
      {
        field: 'cate',
        headerName: 'Category',
        sortable: isSortable,
        flex: 1,
        minWidth: 100,
        cellClassName: 'action-plan-app--cell-cate',
        sortComparator: sortCol(categoryMap),
        valueGetter: (params: GridValueGetterParams<GoalCategory>) =>
          GoalCategory[
            params?.row?.node?.category as keyof typeof GoalCategory
          ],
        renderCell: (params: GridRenderCellParams<string>) => {
          const goalCategory = params?.row?.node
            ?.category as keyof typeof GoalCategory
          return (
            <GoalTag
              sx={{
                color:
                  CATEGORY_COLOR_5[
                    GoalCategory[goalCategory as keyof typeof GoalCategory]
                  ]
              }}
              label={parseGoalCategory(goalCategory)}
            />
          )
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: isSortable,
        flex: 1.5,
        minWidth: 140,
        sortComparator: sortCol(statusMap),
        valueGetter: (params: GridValueGetterParams<GoalStatus>) =>
          GoalStatus[params?.row?.node?.status as keyof typeof GoalStatus],
        renderCell: (params: GridRenderCellParams<GoalStatus>) => {
          if (params?.row?.node?.status) {
            return (
              <StatusChip
                label={
                  GoalStatus[
                    params?.row?.node?.status as keyof typeof GoalStatus
                  ]
                }
              />
            )
          }
          return ''
        }
      },
      {
        field: 'timeframe',
        headerName: 'Timeframe',
        sortable: isSortable,
        flex: 2,
        minWidth: 200,
        type: 'date',
        valueGetter: (params: GridValueGetterParams<Date>) =>
          params?.row?.node?.startDate,
        renderCell: (params: GridRenderCellParams<string>) => {
          const startDate = params?.row?.node?.startDate
            ? moment(params?.row?.node?.startDate).format('MMM YYYY')
            : ''
          const endDate = params?.row?.node?.endDate
            ? moment(params?.row?.node?.endDate).format('MMM YYYY')
            : ''
          return (
            <Stack
              direction='row'
              spacing={1}
              alignItems='baseline'
              justifyContent='space-evenly'
              width='100%'
            >
              <Typography width='60px' variant='body16Light'>
                {startDate}
              </Typography>
              <Box>
                <East
                  sx={{
                    fontSize: '14px',
                    verticalAlign: '-2px',
                    marginLeft: '6px'
                  }}
                />
              </Box>
              <Typography width='60px' variant='body16Light'>
                {endDate}
              </Typography>
            </Stack>
          )
        }
      },
      {
        field: 'impact',
        headerName: 'Impact',
        sortable: isSortable,
        flex: 1,
        minWidth: 75,
        sortComparator: sortCol(impactEffortMap),
        valueGetter: (params: GridValueGetterParams<GoalLevel>) =>
          params?.row?.node?.impact,
        renderCell: (params: GridRenderCellParams<GoalLevel>) => {
          if (params?.row?.node?.impact) {
            return (
              <Stack
                className='action-plan-app--cell-imp'
                direction='row'
                spacing={2}
              >
                {params?.row?.node?.impact === 'high' && (
                  <>
                    <Typography>High</Typography>
                    <ArrowUpward sx={{ color: '#32B996' }} />
                  </>
                )}
                {params?.row?.node?.impact === 'medium' && (
                  <>
                    <Typography>Med</Typography>
                    <ArrowForward sx={{ color: '#FCA625' }} />
                  </>
                )}
                {params?.row?.node?.impact === 'low' && (
                  <>
                    <Typography>Low</Typography>
                    <ArrowDownward sx={{ color: '#FE6868' }} />
                  </>
                )}
              </Stack>
            )
          }
          return ''
        }
      },
      {
        field: 'effort',
        headerName: 'Effort',
        sortable: isSortable,
        flex: 1,
        minWidth: 75,
        sortComparator: sortCol(impactEffortMap),
        valueGetter: (params: GridValueGetterParams<GoalLevel>) =>
          params?.row?.node?.effort,
        renderCell: (params: GridRenderCellParams<Member>) => {
          if (params?.row?.node?.effort) {
            return (
              <Stack
                className='action-plan-app--cell-eff'
                direction='row'
                spacing={2}
              >
                {params?.row?.node?.effort === 'low' && (
                  <>
                    <Typography>Low</Typography>
                    <ArrowDownward sx={{ color: '#32B996' }} />
                  </>
                )}
                {params?.row?.node?.effort === 'medium' && (
                  <>
                    <Typography>Med</Typography>
                    <ArrowForward sx={{ color: '#FCA625' }} />
                  </>
                )}
                {params?.row?.node?.effort === 'high' && (
                  <>
                    <Typography>High</Typography>
                    <ArrowUpward sx={{ color: '#FE6868' }} />
                  </>
                )}
              </Stack>
            )
          }
          return ''
        }
      },
      {
        field: 'owner',
        headerName: 'Owner',
        sortable: isSortable,
        flex: 1,
        minWidth: 75,
        cellClassName: 'action-plan-app--cell-owner',
        valueGetter: (params: GridValueGetterParams<string>) => {
          return (
            params?.row?.node?.owner?.name ??
            params?.row?.node?.owner?.primaryEmail
          )
        },
        renderCell: (params: GridRenderCellParams<string>) =>
          params.value && <UserAvatar username={params.value} size={32} />
      }
    ]
  }
}
