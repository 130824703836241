import React from 'react'
import { Grid, Link, Typography } from '@mui/material'
import {
  ImageInfo,
  InfoButton,
  LeftInfoBlock,
  RightImageBlock
} from 'pages/PageNotFound/ImageInfoBlock/styledComponents'
import { HERO_W_NO_EMPLOYER_GOALS } from 'pages/PageNotFound/ImageInfoBlock/constants'

const NoEmployerGoals = () => {
  return (
    <ImageInfo
      sx={{
        backgroundColor: '#F0E8FF'
      }}
    >
      <LeftInfoBlock>
        <Typography variant='h6' marginBottom={3} flex='1'>
          {HERO_W_NO_EMPLOYER_GOALS.message}
        </Typography>
        <Typography variant='body16Light' marginBottom={5} width='72%'>
          {HERO_W_NO_EMPLOYER_GOALS.desc}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <InfoButton
              href={HERO_W_NO_EMPLOYER_GOALS.btnUrl}
              variant='contained'
            >
              {HERO_W_NO_EMPLOYER_GOALS.btnTitle}
            </InfoButton>
            <Link
              href={HERO_W_NO_EMPLOYER_GOALS.linkUrl}
              target='_blank'
              paddingLeft='33px'
              style={{ textDecoration: 'underline' }}
              variant='linkSmall'
            >
              {HERO_W_NO_EMPLOYER_GOALS.linkTitle}
            </Link>
          </Grid>
        </Grid>
      </LeftInfoBlock>
      <RightImageBlock imageUrl={HERO_W_NO_EMPLOYER_GOALS.image} />
    </ImageInfo>
  )
}

export default NoEmployerGoals
