import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import { ActionPlanContext } from 'context/ActionPlan'
import { PanelSectionTitle } from '../styledComponents'
import OwnerSelect from './OwnerSelect'
import TeamSelect from './TeamSelect'
import ResourcesSection from './ResourcesSection'
import NotesSection from './NotesSection'
import ImpactEffortSection from './ImpactEffortSection'
import TimeframeSelect from './TimeframeSelect'
import ActionsToConsiderSection from './ActionsToConsiderSection'

const PanelDetails = () => {
  const { selectedEmployerGoal } = useContext(ActionPlanContext)
  if (!selectedEmployerGoal) return null

  return (
    <>
      <PanelSectionTitle sectionTitle='The team' />
      <Grid container spacing={3}>
        <OwnerSelect />
        <TeamSelect />
      </Grid>
      <PanelSectionTitle sectionTitle='Goal details' />
      <Grid container spacing={3}>
        <ImpactEffortSection />
        <TimeframeSelect />
      </Grid>
      {selectedEmployerGoal?.createdType === 'mathison' && (
        <>
          <ActionsToConsiderSection />
          <PanelSectionTitle sectionTitle='Mathison Resources' />
          <Grid container spacing={3}>
            <ResourcesSection />
          </Grid>
          {/* <PanelSectionTitle sectionTitle='Supporting data' />
      <Grid container>
        <SupportingDataSection />
      </Grid> */}
        </>
      )}
      <PanelSectionTitle sectionTitle='Notes' />
      <Grid container>
        <NotesSection />
      </Grid>
    </>
  )
}

export default PanelDetails
