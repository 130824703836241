import React, { ReactNode } from 'react'
import { Paper } from '@mui/material'
import { CATEGORY_COLOR_1_BKGD } from 'constants/'
import { GoalCategory } from 'model/actionPlan'

interface Props {
  children: ReactNode
  dense?: boolean
  goalCategory?: string
}

const DescriptionBanner = ({ children, dense, goalCategory }: Props) => {
  const categoryName = GoalCategory[goalCategory as keyof typeof GoalCategory]

  return (
    <Paper
      sx={{
        padding: dense ? '16px' : '20px',
        marginTop: 3,
        borderRadius: 2,
        bgcolor: CATEGORY_COLOR_1_BKGD[categoryName]
      }}
      elevation={0}
    >
      {children}
    </Paper>
  )
}
DescriptionBanner.defaultProps = {
  dense: false,
  goalCategory: GoalCategory.attracting_sourcing
}

export default DescriptionBanner
