import React, { useContext } from 'react'
import { Grid, Link } from '@mui/material'
import { RecommendationsContext } from 'context/Recommendations'
import { parseUrl, convertUrlToRelativePath } from 'utils/KCUrlPaser'
import KCItem from './KCItem'

const KCLinks = () => {
  const { selectedReco } = useContext(RecommendationsContext)
  const { category, kcUrls } = selectedReco!
  return (
    <Grid
      container
      spacing={3}
      sx={{ marginTop: 3, '& .MuiGrid-item': { paddingTop: 0 } }}
    >
      {kcUrls.map((link: string) => {
        const content = parseUrl(link)
        const relativeLink = convertUrlToRelativePath(link)
        return (
          <Grid item xs={6}>
            <Link
              href={relativeLink}
              target='_blank'
              rel='noreferrer'
              style={{
                color: 'unset',
                textDecoration: 'unset',
                position: 'relative',
                display: 'block'
              }}
            >
              <KCItem content={content} goalCategory={category} />
            </Link>
          </Grid>
        )
      })}
    </Grid>
  )
}
export default KCLinks
