import React, { useContext } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { RecommendationsContext } from 'context/Recommendations'
import { MicroServiceContext } from 'context/MicroService'
import { EHIAccess } from 'model/access'
import { GoalCard } from 'components'
import KCLinks from './KCLinks'
import HeaderActions from './HeaderActions'
import Description from './Description'
import ActionsToConsiderSection from './ActionsToConsiderSection'
import { ExpandedRecoSectionTitle } from './styledComponents'

const ExpandedGoal = () => {
  const {
    currentAccount: { ehiAccess }
  } = useContext(MicroServiceContext)
  const { selectedReco } = useContext(RecommendationsContext)
  if (!selectedReco) {
    return <GoalCard sx={{ padding: '32px', height: '100%' }} selected />
  }
  const { title } = selectedReco

  const isReadOnly = ehiAccess !== EHIAccess.FULL_EHI_ACCESS

  return (
    <GoalCard sx={{ padding: '32px' }} selected>
      {!isReadOnly && <HeaderActions />}
      <Box>
        <Grid container tabIndex={0}>
          <Typography variant='h2'>{title}</Typography>
        </Grid>
        <Description />
        <ActionsToConsiderSection />
        <ExpandedRecoSectionTitle sectionTitle='Mathison Resources' />
        <KCLinks />
      </Box>
    </GoalCard>
  )
}
export default ExpandedGoal
