import React from 'react'
import { Grid, Typography } from '@mui/material'
import { UserAvatar } from 'components'

interface Props {
  name: string
  primaryEmail: string
}
const MenuItemMember = ({ name, primaryEmail }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <UserAvatar size={32} username={name ?? primaryEmail} />
      </Grid>
      <Grid item>
        <Typography variant='body2' component='div' fontWeight={600}>
          {name}
        </Typography>
        <Typography variant='body2'>{primaryEmail}</Typography>
      </Grid>
    </Grid>
  )
}

export default MenuItemMember
