import React, { useContext } from 'react'
import { RecommendationsContext } from 'context/Recommendations'
import { Box, Typography } from '@mui/material'

const Description = () => {
  const { selectedReco } = useContext(RecommendationsContext)
  if (!selectedReco) return null
  const { description } = selectedReco!
  return description ? (
    <Box paddingTop={3} maxWidth='457px'>
      <Typography variant='body16Light'>{description}</Typography>
    </Box>
  ) : null
}

export default Description
