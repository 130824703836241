/* eslint-disable no-console */
import React, { useContext, useRef, useState } from 'react'
import {
  DataGridPro,
  GridCallbackDetails,
  GridEventListener,
  GridRowOrderChangeParams,
  GridRowParams,
  MuiEvent
} from '@mui/x-data-grid-pro'
import { ActionPlanContext } from 'context/ActionPlan'
import { Color, Divider } from 'components'
import { Stack } from '@mui/material'
import { ActionPlanFilterContext } from 'context/ActionPlan/Filter'
import LoadingCircular from 'pages/Recommendations/CategoryTabPanel/LoadingCircular'
import SidePanel from 'components/SidePanel'
import { getActionPlan } from './config'
import UserControlsHeader from './UserControlsHeader'
import NoResultsDataGridOverlay from './NoResultsDataGridOverlay'
import SidePanelContent from '../SidePanel/SidePanelContent'

const EmployerGoalDataGrid = () => {
  const {
    isLoading,
    orderedEmployerGoals: employerGoals,
    loadSelectedEmployerGoalAndSearchParams,
    submitEmployerGoalRank
  } = useContext(ActionPlanContext)
  const { filterModel, isSortable, gridKey } = useContext(
    ActionPlanFilterContext
  )
  const [pageSize, setPageSize] = useState<number>(15)

  const sidePanelRef = useRef<any>(null)

  const handleRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams
  ) => {
    const goalId = params?.row?.node?.id
    loadSelectedEmployerGoalAndSearchParams(goalId)
    sidePanelRef.current?.openSidePanel()
  }

  const beforePanelOnClose: any = () => {
    loadSelectedEmployerGoalAndSearchParams()
  }

  const panelOverrideProps = {
    beforePanelOnClose
  }

  const handleRowOrderChange: GridEventListener<'rowOrderChange'> = async (
    params: GridRowOrderChangeParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    const node = params.row?.node
    if (node) {
      submitEmployerGoalRank(node.id, params.targetIndex + 1)
    }
  }

  const dataGridProps = {
    loading: isLoading,
    getRowId: (row: any) => row?.node?.id,
    autoHeight: true,
    rowHeight: 88,
    components: {
      NoResultsOverlay: NoResultsDataGridOverlay
    },
    disableColumnMenu: true,
    rows: employerGoals,
    columns: getActionPlan(isSortable).columns,
    pageSize,
    onPageSizeChange: (newPageSize: number) => setPageSize(newPageSize),
    rowsPerPageOptions: [10, 15, 25],
    disableColumnFilter: !isSortable,
    rowReordering: !isSortable,
    pagination: isSortable,
    disableColumnResize: isSortable,
    filterModel,
    onRowClick: handleRowClick,
    onRowOrderChange: handleRowOrderChange
  }

  if (isLoading) return <LoadingCircular />

  return (
    <Stack
      padding={4}
      borderRadius={2}
      border={`1px solid ${Color.grey300}`}
      sx={{
        backgroundColor: '#FFF',
        '& .MuiDataGrid-root': {
          '& .MuiDataGrid-columnHeader--filtered': {
            '& [aria-label~=filter].MuiDataGrid-iconButtonContainer ': {
              visibility: 'hidden',
              width: 0
            },
            '& .MuiDataGrid-columnHeaderDraggableContainer': {
              width: 'auto'
            }
          },
          '& .MuiDataGrid-columnHeadersInner': {
            height: '44px',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600
            }
          },
          '& .MuiIconButton-root': {
            color: '#050B0E'
          },
          '& .MuiDataGrid-columnHeader--alignRight.app-plan-header-rank .MuiDataGrid-columnHeaderTitleContainer':
            {
              flexDirection: 'row'
            },
          '& .MuiDataGrid-cell--textRight.action-plan-app--cell-rank': {
            justifyContent: 'flex-start'
          },
          '& .MuiDataGrid-columnSeparator .MuiSvgIcon-root': {
            color: '#E5E5E5',
            transform: 'scale(.5,2)'
          },
          '& .MuiDataGrid-cell.MuiDataGrid-rowReorderCellContainer:focus-within':
            {
              outline: 'none',
              borderBottom: 'solid black'
            },
          '& .MuiDataGrid-row--dragging': {
            '& .MuiDataGrid-rowReorderCellPlaceholder ': {
              display: '-webkit-box',
              wordBreak: 'break-word',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',
              whiteSpace: 'normal',
              width: '359px'
            }
          },
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
            '& .action-plan-app--cell-cate': {
              '& .MuiChip-root': {
                maxHeight: '32px'
              },
              '& .MuiChip-label': {
                lineHeight: '16px',
                display: 'flex',
                alignItems: 'center',
                padding: '8px 0 8px 1px'
              }
            },
            '& .action-plan-app--cell-eff, .action-plan-app--cell-imp': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '& .MuiSvgIcon-root': {
                fontSize: '16px',
                marginLeft: '8px'
              }
            },
            '& .action-plan-app--cell-owner': {
              '& .MuiTypography-root': {
                fontFamily: 'IBM Plex Sans',
                fontSize: '12px',
                fontWeight: 700,
                lineHeight: '16px'
              }
            }
          }
        },
        '& .MuiDataGrid-footerContainer': {
          paddingTop: '32px',
          '& .MuiTablePagination-toolbar': {
            minHeight: '40px',
            '& p': {
              margin: '10px 0'
            }
          }
        }
      }}
    >
      <UserControlsHeader />
      <Divider sx={{ margin: '24px 0 40px' }} />
      <DataGridPro {...dataGridProps} sx={{ border: 0 }} key={gridKey} />
      <SidePanel ref={sidePanelRef} {...panelOverrideProps}>
        <SidePanelContent />
      </SidePanel>
    </Stack>
  )
}
export default EmployerGoalDataGrid
