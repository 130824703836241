import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import {
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Typography
} from '@mui/material'
import { Color, Divider, StatusTab, StatusTabList } from 'components'
import { CategoryAllMap, RecoStatus } from 'model/recommendations'
import { GoalCategory } from 'model/actionPlan'
import { RecommendationsContext } from 'context/Recommendations'
import { errorPathname } from 'routes/constants'

import { theme } from '@mathison-inc/components'
import LoadingCircular from './LoadingCircular'
import CardView from '../CardView'
import ElevationScroll from '../ElevationScroll'

const CategoryPanel = () => {
  const {
    category,
    setCategory,
    setStatus,
    status,
    recos,
    isLoading,
    hasAccessToSearchParamGoal
  } = useContext(RecommendationsContext)
  if (isLoading) return <LoadingCircular />

  if (recos.length > 0 || status !== 'all') {
    if (hasAccessToSearchParamGoal) {
      return (
        <Grid item container marginTop='16px' flexGrow={1} direction='column'>
          <Grid
            item
            sx={{
              position: 'sticky',
              top: `56px`,
              zIndex: 1200,
              borderBottom: `1px solid ${Color.grey300}`
            }}
          />
          <ElevationScroll>
            <Grid
              item
              container
              padding='12px 32px'
              alignItems='end'
              borderLeft={`1px solid ${Color.grey300}`}
              borderRight={`1px solid ${Color.grey300}`}
              sx={{
                bgcolor: '#fff',
                position: 'sticky',
                top: `${56 + 49}px`,
                zIndex: 1200,
                transition: 'box-shadow 0.3s'
              }}
            >
              <Grid item xs={3.5} container justifyContent='flex-start'>
                <FormControl
                  fullWidth
                  variant='filled'
                  sx={{
                    '& .MuiInputBase-root .MuiSelect-select': {
                      paddingTop: '30px',
                      paddingBottom: '10px',
                      paddingLeft: '16px'
                    },
                    '& .MuiInputLabel-shrink': {
                      lineHeight: '20px',
                      transform: 'translate(16px, 10px) scale(1)',
                      [theme.breakpoints.down(1269)]: {
                        width: '80%'
                      }
                    }
                  }}
                >
                  <InputLabel>
                    <Typography variant='body14Light'>
                      View recommendations by category
                    </Typography>
                  </InputLabel>
                  <Select
                    value={category}
                    label='View recommendations by category'
                    onChange={e => {
                      setCategory(e.target.value as GoalCategory)
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 66,
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      PaperProps: { sx: { maxHeight: 270 } }
                    }}
                    sx={{
                      '& .MuiSelect-select .MuiTypography-root': {
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '24px'
                      }
                    }}
                  >
                    {CategoryAllMap.map(({ label, value }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        sx={{
                          paddingLeft: '12px'
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography
                              variant='body2'
                              component='div'
                              fontWeight={400}
                            >
                              {label}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
                container
                justifyContent='flex-end'
                sx={{
                  [theme.breakpoints.down(1269)]: {
                    display: 'flex',
                    flexDirection: 'column',
                    orientation: 'vertical'
                  }
                }}
              />
              <Grid
                item
                xs={12}
                md={6.5}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  width: '100%',
                  [theme.breakpoints.down('md')]: {
                    flexDirection: 'column'
                  }
                }}
              >
                <StatusTabList
                  TabIndicatorProps={{ sx: { display: 'none' } }}
                  value={status}
                  onChange={(_: any, value: RecoStatus) =>
                    setStatus(value as RecoStatus)
                  }
                  sx={{
                    marginLeft: 'auto',
                    width: '300px',
                    '.MuiTabs-flexContainer': {
                      flexDirection: { xs: 'column', md: 'row' }
                    },
                    '.MuiTab-root': {
                      width: { xs: '100%', md: 'auto' },
                      justifyContent: { xs: 'flex-start', md: 'center' }
                    }
                  }}
                >
                  <StatusTab
                    value={RecoStatus.recommended || RecoStatus.unarchived}
                    label='New'
                  />
                  <StatusTab value={RecoStatus.all} label='All' />
                  <StatusTab value={RecoStatus.converted} label='Saved' />
                  <StatusTab value={RecoStatus.archived} label='Archived' />
                </StatusTabList>
              </Grid>
              <Divider
                sx={{
                  paddingTop: '12px'
                }}
              />
            </Grid>
          </ElevationScroll>
          <CardView recos={recos} />
        </Grid>
      )
    }
    return <Navigate to={errorPathname} state={{ pageType: 'view-goals' }} />
  }
  return <Navigate to={errorPathname} state={{ pageType: 'zero-goals' }} />
}

export default CategoryPanel
