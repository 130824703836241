import React from 'react'
import { SharedActionPlanProvider } from 'context/SharedActionPlan'
import ActionPlan from './ActionPlan'
import Recommendations from './Recommendations'

interface Props {
  isDefault?: boolean
}

const GoalPageVariant = ({ isDefault }: Props) => {
  return (
    <SharedActionPlanProvider>
      {isDefault ? <ActionPlan /> : <Recommendations />}
    </SharedActionPlanProvider>
  )
}
GoalPageVariant.defaultProps = {
  isDefault: false
}

export default GoalPageVariant
