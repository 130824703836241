import React, { createContext, useMemo } from 'react'
import useEmployerGoals from 'hooks/useEmployerGoals'
import { GoalCounter } from 'model/actionPlan'
import { GoalCountInit } from 'constants/ActionPlan'
import { getGoalCount } from 'utils/actionPlan'
import useSessionStorage from 'hooks/useSessionStorage'

export type SharedActionPlan = {
  isLoading: boolean
  employerGoals: any
  employerGoalCount: GoalCounter
  isAlertActive: boolean
  setIsAlertActive: (bool: boolean) => void
}

export const SharedActionPlanContext = createContext<SharedActionPlan>({
  isLoading: true,
  employerGoals: { edges: [] },
  employerGoalCount: GoalCountInit,
  isAlertActive: true,
  setIsAlertActive: () => {}
})

export const SharedActionPlanProvider = ({ children }: any): JSX.Element => {
  const { employerGoals } = useEmployerGoals()
  const isLoading = !employerGoals

  let employerGoalCount: GoalCounter = GoalCountInit
  if (employerGoals) {
    const edges = [...employerGoals.edges]
    employerGoalCount = getGoalCount(edges)
  }
  const [isAlertActive, setIsAlertActive] = useSessionStorage({
    storageKey: 'is-alert-active',
    fallbackState: true
  })

  const sharedActionPlanValues = useMemo(
    () => ({
      isLoading,
      employerGoals,
      employerGoalCount,
      isAlertActive,
      setIsAlertActive
    }),
    [
      isLoading,
      employerGoals,
      employerGoalCount,
      isAlertActive,
      setIsAlertActive
    ]
  )

  return (
    <SharedActionPlanContext.Provider value={sharedActionPlanValues}>
      {children}
    </SharedActionPlanContext.Provider>
  )
}
