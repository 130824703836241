import React, { useContext } from 'react'
import { GoalCard, GoalTag, theme } from 'components'
import { Typography, Grid, Box } from '@mui/material'
import { RecommendationsContext } from 'context/Recommendations'
import { parseGoalCategory } from 'utils/actionPlan'
import { Reco } from 'model/recommendations'

interface Props {
  reco: Reco
}

const Card = (props: Props) => {
  const { reco } = props
  const { setSelectedRecoId, selectedReco } = useContext(RecommendationsContext)
  let statusChip = 'RECOMMENDATION'

  if (reco.status === 'converted') {
    statusChip = 'SAVED GOAL'
  }
  if (reco.status === 'archived') {
    statusChip = 'ARCHIVED'
  }

  return (
    <GoalCard
      selected={selectedReco?.id === reco.id}
      sx={{
        marginBottom: 2,
        [theme.breakpoints.down('sm')]: {
          padding: '8px'
        }
      }}
      disabled={reco?.status === 'archived'}
      onClick={() => {
        setSelectedRecoId(reco.id)
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: theme.spacing(1),
              marginBottom: 2
            }}
          >
            <GoalTag label={parseGoalCategory(reco.category)} />
            <GoalTag label={statusChip} variant='outlined' />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='body1Medium'
            sx={{
              display: '-webkit-box',
              wordBreak: 'break-word',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {reco.title}
          </Typography>
        </Grid>
      </Grid>
    </GoalCard>
  )
}

export default Card
