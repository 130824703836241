import React, { useContext } from 'react'
import { Stack } from '@mui/material'
import { ActionPlanProvider } from 'context/ActionPlan'
import { ActionPlanFilterProvider } from 'context/ActionPlan/Filter'
import { SharedActionPlanContext } from 'context/SharedActionPlan'
import Header from './Header'
import EmployerGoalDataGrid from './EmployerGoalDataGrid'
import NoEmployerGoals from './NoEmployerGoals'

const ActionPlan = () => {
  const { isLoading, employerGoals } = useContext(SharedActionPlanContext)

  return (
    <Stack spacing={4}>
      {isLoading || employerGoals?.edges?.length > 0 ? (
        <ActionPlanProvider>
          <Header />
          <ActionPlanFilterProvider>
            <EmployerGoalDataGrid />
          </ActionPlanFilterProvider>
        </ActionPlanProvider>
      ) : (
        <>
          <Header />
          <NoEmployerGoals />
        </>
      )}
    </Stack>
  )
}

export default ActionPlan
