import { RecoNode, RecoStatus } from 'model/recommendations'

export const filterRecosByCategory = (
  recoNodes: RecoNode[],
  category: string
) => {
  const result = recoNodes

  if (category === 'all') {
    return result
  }

  return result.filter((reco: RecoNode) => reco.node.category === category)
}

export const sortRecosByStatus = (
  recoNodes: RecoNode[],
  status: RecoStatus
) => {
  const result = recoNodes

  if (status === 'all') {
    return result
  }

  return result.filter((reco: RecoNode) => reco.node.status === status)
}
